/** @format */

import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import apiClient from "../apiClient";

// Hook for fetching all admins
export const useParentQuery = () => {
  return useQuery({
    queryKey: ["parents"],
    queryFn: async () => {
      const { data } = await apiClient.get("api/parents/all-parents");
      return data;
    },
  });
};
// Hook for adding a new parent
export const useAddParentMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (parent) => {
      return (await apiClient.post("api/admins/add-parent", parent)).data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries(["parents"]);
    },
  });
};
