import React, { useState } from "react";
import toast from "react-hot-toast";
import { useAddAdminMutation } from "../hooks/admin";
import SideBar from "../Components/SideBar";

const UpdateAccount = () => {
  const { mutate: addAdmin } = useAddAdminMutation();
  const [formData, setFormData] = useState({
    full_name: "",
    email: "",
    date_of_birth: "",
    religion: "",
    gender: "",
    bio: "",
    profile_pic: "",
    status: "",
    password: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleFileChange = (e) => {
    const { name, files } = e.target;
    setFormData({
      ...formData,
      [name]: files[0],
    });
  };

  const handleSubmit = (e) => {
    try {
      e.preventDefault();
      addAdmin(formData);

      toast.success("added successfully");
    } catch (error) {
      toast.error(error);
    }
  };

  return (
    <SideBar>
      <div className="container-fluid">
        <div className="col-md-12">
          <div className="card p-4">
            <h5 className="text-center my-4">Update Profile</h5>
            <div className="row">
              <form onSubmit={handleSubmit}>
                <div className="box-body">
                  <div className="row">
                    <div className="form-group no-margin col-md-6">
                      <label>
                        Full Name <span style={{ color: "red" }}>*</span>
                      </label>
                      <input
                        type="text"
                        name="full_name"
                        className="form-control"
                        placeholder="Enter Name"
                        value={formData.full_name}
                        onChange={handleChange}
                        required
                      />
                    </div>
                    <div className="form-group no-margin col-md-6">
                      <label>
                        Email address <span style={{ color: "red" }}>*</span>
                      </label>
                      <input
                        type="email"
                        className="form-control"
                        name="email"
                        placeholder="Enter email"
                        value={formData.email}
                        onChange={handleChange}
                        required
                      />
                    </div>
                    <div className="form-group no-margin col-md-6">
                      <label>
                        DOB <span style={{ color: "red" }}>*</span>
                      </label>
                      <input
                        type="date"
                        className="form-control"
                        name="date_of_birth"
                        value={formData.date_of_birth}
                        onChange={handleChange}
                        required
                      />
                    </div>
                    <div className="form-group no-margin col-md-6">
                      <label>
                        Religion <span style={{ color: "red" }}>*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name="religion"
                        placeholder="Religion"
                        value={formData.religion}
                        onChange={handleChange}
                        required
                      />
                    </div>
                    <div className="form-group no-margin col-md-6">
                      <label>
                        Gender<span style={{ color: "red" }}>*</span>
                      </label>
                      <select
                        className="form-control"
                        value={formData.gender}
                        onChange={handleChange}
                        name="gender"
                        required
                      >
                        <option value="">Select Gender</option>
                        <option value="MALE">Male</option>
                        <option value="FEMALE">Female</option>
                        <option value="OTHER">Other</option>
                      </select>
                    </div>
                    <div className="form-group no-margin col-md-6">
                      <label>
                        Bio <span style={{ color: "red" }}>*</span>
                      </label>
                      <textarea
                        className="form-control"
                        name="bio"
                        value={formData.bio}
                        onChange={handleChange}
                        placeholder="About Me"
                        required
                      ></textarea>
                    </div>
                    <div className="form-group no-margin col-md-6">
                      <label>
                        Password <span style={{ color: "red" }}>*</span>
                      </label>
                      <input
                        type="password"
                        name="password"
                        value={formData.password}
                        onChange={handleChange}
                        className="form-control"
                        placeholder="Password"
                        required
                      />
                    </div>
                    <div className="form-group no-margin col-md-6">
                      <label>Status</label>
                      <select
                        className="form-control"
                        name="status"
                        value={formData.status}
                        onChange={handleChange}
                      >
                        <option value="">Select Status</option>
                        <option value="ACTIVE">Active</option>
                        <option value="INACTIVE">Inactive</option>
                      </select>
                    </div>
                    <div className="form-group no-margin col-md-6">
                      <label>
                        File input <span style={{ color: "red" }}>*</span>
                      </label>
                      <input
                        type="file"
                        className="form-control"
                        name="profile_pic"
                        onChange={handleFileChange}
                      />
                    </div>
                  </div>
                </div>
                <div className="box-footer">
                  <button type="submit" className="btn btn-primary">
                    Update
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </SideBar>
  );
};

export default UpdateAccount;
