import React from "react";

function AdminItems() {
  return (
    <div className="row">
      {/*  <!-- Admin--> */}
      <div className="col-xl-3 col-md-6 mb-4">
        <div className="card border-left-info bg-secondary shadow h-100 py-2">
          <div className="card-body">
            <div className="row no-gutters align-items-center">
              <div className="col mr-2">
                <div className="text-xs font-weight-bold text-white text-uppercase mb-1">
                  ADMIN
                </div>
                <div className="h5 mb-0 font-weight-bold text-white">4</div>
              </div>
              <div className="col-auto">
                <i className="fas fa-users fa-2x text-white"></i>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/*  <!-- Teachers--> */}
      <div className="col-xl-3 col-md-6 mb-4">
        <div className="card border-left-success bg-primary shadow h-100 py-2">
          <div className="card-body">
            <div className="row no-gutters align-items-center">
              <div className="col mr-2">
                <div className="text-xs font-weight-bold text-white text-uppercase mb-1">
                  TEACHERS
                </div>
                <div className="h5 mb-0 font-weight-bold text-white">21</div>
              </div>
              <div className="col-auto">
                <i className="fas fa-users fa-2x text-white"></i>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/*  <!-- students --> */}
      <div className="col-xl-3 col-md-6 mb-4">
        <div className="card border-left-danger bg-info shadow h-100 py-2">
          <div className="card-body">
            <div className="row no-gutters align-items-center">
              <div className="col mr-2">
                <div className="text-xs font-weight-bold text-white text-uppercase mb-1">
                  STUDENTS
                </div>
                <div className="row no-gutters align-items-center">
                  <div className="col-auto">
                    <div className="h5 mb-0 mr-3 font-weight-bold text-white">
                      157
                    </div>
                  </div>
                  <div className="col">
                    <div className="progress">
                      <div
                        className="progress-bar bg-success"
                        style={{ width: "70%" }}
                      ></div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-auto">
                <i className="fas fa-clipboard-list fa-2x text-white"></i>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/*  <!-- parent --> */}
      <div className="col-xl-3 col-md-6 mb-4">
        <div className="card border-left-secondary bg-danger shadow h-100 py-2">
          <div className="card-body">
            <div className="row no-gutters align-items-center">
              <div className="col mr-2">
                <div className="text-xs font-weight-bold text-white text-uppercase mb-1">
                  PARENT
                </div>
                <div className="h5 mb-0 font-weight-bold text-white">0</div>
              </div>
              <div className="col-auto">
                <i className="fas fa-users fa-2x text-white"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* classes */}
      <div className="col-xl-3 col-md-6 mb-4">
        <div className="card border-left-success bg-warning shadow h-100 py-2">
          <div className="card-body">
            <div className="row no-gutters align-items-center">
              <div className="col mr-2">
                <div className="text-xs font-weight-bold text-white text-uppercase mb-1">
                  CLASSES
                </div>
                <div className="h5 mb-0 font-weight-bold text-white">19</div>
              </div>
              <div className="col-auto">
                <i className="fas fa-home fa-2x text-white"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* subjects */}
      <div className="col-xl-3 col-md-6 mb-4">
        <div className="card border-left-secondary bg-success shadow h-100 py-2">
          <div className="card-body">
            <div className="row no-gutters align-items-center">
              <div className="col mr-2">
                <div className="text-xs font-weight-bold text-white text-uppercase mb-1">
                  SUBJECTS
                </div>
                <div className="h5 mb-0 font-weight-bold text-white">41</div>
              </div>
              <div className="col-auto">
                <i className="fas fa-book fa-2x text-white"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* terms/session */}
      <div className="col-xl-3 col-md-6 mb-4">
        <div className="card border-left-primary bg-secondary shadow h-100 py-2">
          <div className="card-body">
            <div className="row no-gutters align-items-center">
              <div className="col mr-2">
                <div className="text-xs font-weight-bold text-white text-uppercase mb-1">
                  TERMS/SESSION
                </div>
                <div className="h5 mb-0 font-weight-bold text-white">4</div>
              </div>
              <div className="col-auto">
                <i className="fas fa-times fa-2x text-white"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* BLOG POST */}
      <div className="col-xl-3 col-md-6 mb-4">
        <div className="card border-left-warning bg-primary shadow h-100 py-2">
          <div className="card-body">
            <div className="row no-gutters align-items-center">
              <div className="col mr-2">
                <div className="text-xs font-weight-bold text-white text-uppercase mb-1">
                  BLOG POST
                </div>
                <div className="h5 mb-0 font-weight-bold text-white">1</div>
              </div>
              <div className="col-auto">
                <i className="fas fa-book fa-2x text-white"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AdminItems;
