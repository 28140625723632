import React, { useContext, useState } from "react";
import toast from "react-hot-toast";
import SideBar from "../Components/SideBar";
import { Store } from "../Store";
import { useSaveAttendanceMutation } from "../hooks/admin";
import { useClassesQuery } from "../hooks/classHook";
import { useStudentsQuery } from "../hooks/studentHook";
import { useTeachersQuery } from "../hooks/teacherHook";

const AttendanceForm = ({ setClassId, onSearch, onReset }) => {
  const [attendanceDate, setAttendanceDate] = useState("2024-09-01");
  const { data: classes } = useClassesQuery();

  const handleClassChange = (e) => {
    setClassId(e.target.value);
  };

  const handleDateChange = (e) => {
    setAttendanceDate(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSearch();
  };

  return (
    <section className="content">
      <div className="row">
        <div className="col-md-12">
          <div className="box box-primary">
            <div className="box-header with-border">
              <h3 className="box-title">Search Attendance</h3>
            </div>
            <form onSubmit={handleSubmit}>
              <div className="box-body">
                <div className="row">
                  <div className="form-group col-md-4 pb-3">
                    <label htmlFor="getClass">Class</label>
                    <select
                      className="form-control"
                      name="class_id"
                      id="getClass"
                      required
                      onChange={handleClassChange}
                    >
                      <option value="">Select Class</option>
                      {classes?.map((classItem) => (
                        <option key={classItem.id} value={classItem.Name}>
                          {classItem.Name}
                        </option>
                      ))}
                    </select>
                  </div>

                  <div className="form-group col-md-4 pb-3">
                    <label htmlFor="getAttendanceDate">Attendance Date</label>
                    <input
                      type="date"
                      className="form-control"
                      name="attendance_date"
                      id="getAttendanceDate"
                      required
                      value={attendanceDate}
                      onChange={handleDateChange}
                    />
                  </div>

                  <div
                    className="form-group col-md-4"
                    style={{ paddingTop: "24px", paddingBottom: "20px" }}
                  >
                    <button className="btn btn-primary" type="submit">
                      Search
                    </button>
                    <button
                      type="button"
                      className="btn btn-success ml-3"
                      onClick={onReset}
                    >
                      Reset
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};

const AttendanceList = ({ students }) => {
  const { mutateAsync: saveAttendance } = useSaveAttendanceMutation();

  const {
    state: { userInfo },
  } = useContext(Store);

  const { data: teachers } = useTeachersQuery();

  // Using optional chaining
  const user = teachers.find(
    (teacher) => teacher.email === userInfo.user
  )?.username;

  console.log("Logged in user:", user);

  const [attendanceData, setAttendanceData] = useState({});

  const handleAttendanceChange = (e, student) => {
    const attendanceStatus = e.target.value;

    setAttendanceData((prev) => {
      const updatedAttendanceData = {
        ...prev,
        [student.id]: {
          studentName: student.username,
          class: student.class,
          attendanceStatus: attendanceStatus,
          collectedBy: user, // Should be correct now
          date: new Date().toISOString().split("T")[0],
        },
      };

      return updatedAttendanceData;
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const attendanceArray = Object.values(attendanceData);

      for (const attendance of attendanceArray) {
        await saveAttendance(attendance);
      }
      toast.success("Saved");
    } catch (error) {
      toast.error("Network error");
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="row">
        <div className="col-md-12" style={{ overflowX: "auto" }}>
          <div className="box">
            <div className="box-header">
              <h3 className="box-title">Attendance List</h3>
            </div>
            <div className="w-100" style={{ overflowX: "auto" }}>
              {students.length > 0 ? (
                <table className="table w-100 table-hover">
                  <thead>
                    <tr>
                      <th className="text-uppercase">SN</th>
                      <th className="text-uppercase">Student Name</th>
                      <th className="text-uppercase">Attendance</th>
                    </tr>
                  </thead>
                  <tbody>
                    {students.map((student, index) => (
                      <tr key={student.id}>
                        <td className="mb-0">{index + 1}</td>
                        <td className="mb-0 text-uppercase">
                          <b>{student.username}</b>
                        </td>
                        <td className="text-uppercase">
                          {["Present", "Late", "Absent", "Half Day"].map(
                            (status) => (
                              <label
                                key={status}
                                style={{ marginRight: "20px" }}
                              >
                                <input
                                  type="radio"
                                  value={status}
                                  name={`attendance${student.id}`}
                                  onChange={(e) =>
                                    handleAttendanceChange(e, student)
                                  }
                                />{" "}
                                {status}
                              </label>
                            )
                          )}
                        </td>
                        <td>
                          <button className="btn btn-success" type="submit">
                            Save
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : (
                <p>No students found. Please perform a search.</p>
              )}
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

const AttendancePage = () => {
  const [classId, setClassId] = useState("");
  const [filteredStudents, setFilteredStudents] = useState([]);
  const { data: students } = useStudentsQuery();
  const [hasSearched, setHasSearched] = useState(false);

  const handleSearch = () => {
    const studentsInClass =
      students?.filter((student) => student.class === classId) || [];
    setFilteredStudents(studentsInClass);
    setHasSearched(true);
  };

  const handleReset = () => {
    setClassId("");
    setFilteredStudents([]);
    setHasSearched(false);
  };

  return (
    <SideBar>
      <div className="container-fluid">
        <div className="card p-4">
          <AttendanceForm
            setClassId={setClassId}
            onSearch={handleSearch}
            onReset={handleReset}
          />
          {hasSearched && <AttendanceList students={filteredStudents} />}
        </div>
      </div>
    </SideBar>
  );
};

export default AttendancePage;
