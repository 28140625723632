import React from "react";
import SideBar from "../Components/SideBar";

const UserProfile = () => {
  return (
    <SideBar>
      <section className="container-fluid">
        <div className="card p-4">
          <div className="row">
            {/* Left Column */}
            <div className="col-md-6">
              <div className="box box-primary">
                <div className="box-body box-profile">
                  <div className="row">
                    <div className="col-md-12 text-center">
                      <img
                        className="profile-user-img img-responsive img-circle"
                        src="https://sofis.com.ng/upload/profile/profilepicture.png"
                        alt="User profile"
                        style={{ width: "100px", height: "100px" }}
                      />
                    </div>
                  </div>
                  <h3 className="profile-username text-center">Daniel</h3>
                  <p className="text-muted text-center">Staff</p>
                  <ul className="list-group list-group-unbordered">
                    <li className="list-group-item">
                      <div className="row">
                        <div className="col-md-6">
                          <b>UserID</b>
                        </div>
                        <div className="col-md-6 text-right">
                          <span className="pull-right">238</span>
                        </div>
                      </div>
                    </li>
                    <li className="list-group-item">
                      <div className="row">
                        <div className="col-md-6">
                          <b>Status</b>
                        </div>
                        <div className="col-md-6 text-right">
                          <span
                            className="text-white rounded-lg"
                            style={{ background: "#00a65a" }}
                          >
                            Active
                          </span>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            {/* Right Column */}
            <div className="col-md-6">
              <div className="box box-primary">
                <div className="box-header with-border">
                  <h3 className="box-title">About Me</h3>
                </div>
                <div className="box-body">
                  <strong>
                    <i className="fa fa-user margin-r-5"></i> Full Name
                  </strong>
                  <p className="text-muted">Daniel</p>
                  <hr />
                  <strong>
                    <i className="fa fa-history margin-r-5"></i> DOB
                  </strong>
                  <p className="text-muted">23rd-May-2024</p>
                  <hr />
                  <strong>
                    <i className="fa fa-intersex margin-r-5"></i> Gender
                  </strong>
                  <p className="text-muted">MALE</p>
                  <hr />
                  <strong>
                    <i className="fa fa-book margin-r-5"></i> Religion
                  </strong>
                  <p className="text-muted">Christianity</p>
                  <hr />
                  <strong>
                    <i className="fa fa-envelope margin-r-5"></i> Email
                  </strong>
                  <p className="text-muted">admin@gmail.com</p>
                  <hr />
                  <strong>
                    <i className="fa fa-pencil margin-r-5"></i> Registered on
                  </strong>
                  <p className="text-muted">19th-Mar-2024 / 11:40 AM</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </SideBar>
  );
};

export default UserProfile;
