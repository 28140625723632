/** @format */

import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import apiClient from "../apiClient";

// Hook for fetching all teachers
export const useSubjectQuery = () => {
  return useQuery({
    queryKey: ["subjects"],
    queryFn: async () => {
      const { data } = await apiClient.get("api/subjects/all-subjects");
      return data;
    },
  });
};

// Hook for adding a new class
export const useAddSubjectMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (admin) =>
      (await apiClient.post("api/subjects/add-subject", admin)).data,
    onSuccess: () => {
      queryClient.invalidateQueries(["subjects"]);
    },
  });
};
