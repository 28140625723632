import React from "react";
import { NavLink } from "react-router-dom";

function AdminSideBarItems({ style, changeStyle }) {
  return (
    <ul className={style} id="accordionSidebar">
      {/*  <!-- Sidebar - Brand --> */}
      <div className="d-flex align-items-center justify-content-center align-items-center">
        <NavLink
          className="sidebar-brand d-flex align-items-center justify-content-center"
          to="#"
        >
          <img
            className="h-100 w-100 rounded-circle"
            src="img/undraw_profile.svg"
            alt=""
          />

          <div className="sidebar-brand-text mx-3">Admin</div>
        </NavLink>
        <div className="text-center d-none d-md-inline">
          <button
            className="rounded-circle border-0"
            id="sidebarToggle"
            onClick={changeStyle}
          ></button>
        </div>
      </div>

      {/*   <!-- Divider --> */}
      <hr className="sidebar-divider my-0" />

      {/*  <!-- Nav Item - Dashboard --> */}
      <li className="nav-item active">
        <NavLink className="nav-link" to="/dashboard">
          <i className="fas fa-fw fa-tachometer-alt"></i>
          <span>Dashboard</span>
        </NavLink>
      </li>

      {/*  <!-- Divider --> */}
      <hr className="sidebar-divider" />

      {/*  <!-- Nav Item - Pages Collapse Menu --> */}
      <li className="nav-item">
        <NavLink
          className="nav-link collapsed"
          to="#"
          data-toggle="collapse"
          data-target="#collapseBlogs"
          aria-expanded="true"
          aria-controls="collapseBlogs"
        >
          <i className="fas fa-book text-white"></i>
          <span>Blog</span>
        </NavLink>
        <div
          id="collapseBlogs"
          className="collapse"
          aria-labelledby="headingBlogs"
          data-parent="#accordionSidebar"
        >
          <div className=" collapse-inner bg-white py-2 rounded">
            <NavLink className="collapse-item text-dark" to="/makepost">
              Post
            </NavLink>
            {/* <NavLink
              className="collapse-item text-dark"
              href="forgot-password.html"
            >
              Category
            </NavLink> */}
            <div className="collapse-divider"></div>

            <NavLink
              className="collapse-item text-dark"
              to="/customizeHomePage"
            >
              Customize HomePage
            </NavLink>
            <NavLink className="collapse-item text-dark" href="blank.html">
              Admission
            </NavLink>
            <NavLink className="collapse-item text-dark" href="blank.html">
              Job Application
            </NavLink>
          </div>
        </div>
      </li>

      <li className="nav-item">
        <NavLink
          className="nav-link collapsed"
          to="#"
          data-toggle="collapse"
          data-target="#collapseUsers"
          aria-expanded="true"
          aria-controls="collapseUsers"
        >
          <i className="fas fa-users text-white"></i>
          <span>Users</span>
        </NavLink>
        <div
          id="collapseUsers"
          className="collapse"
          aria-labelledby="headingUsers"
          data-parent="#accordionSidebar"
        >
          <div className="bg-white py-2 collapse-inner rounded">
            <NavLink className="collapse-item text-dark" to="/admin">
              Admin
            </NavLink>
            <NavLink className="collapse-item text-dark" to="/teachers">
              Teachers
            </NavLink>
            <NavLink className="collapse-item text-dark" to="/students">
              Student
            </NavLink>
            <div className="collapse-divider"></div>

            <NavLink className="collapse-item text-dark" to="/parents">
              Parent
            </NavLink>
          </div>
        </div>
      </li>

      {/*  <!-- Nav Item - Tables --> */}
      <li className="nav-item">
        <NavLink
          className="nav-link collapsed"
          to="#"
          data-toggle="collapse"
          data-target="#collapseAcademics"
          aria-expanded="true"
          aria-controls="collapseAcademics"
        >
          <i class="fas fa-user-graduate text-white"></i>
          <span>Academics</span>
        </NavLink>
        <div
          id="collapseAcademics"
          className="collapse"
          aria-labelledby="headingAcademics"
          data-parent="#accordionSidebar"
        >
          <div className="bg-white py-2 collapse-inner rounded">
            <NavLink className="collapse-item text-dark" to="/classes">
              Class
            </NavLink>
            <NavLink className="collapse-item text-dark" to="/subjects">
              Subject
            </NavLink>
            <NavLink
              className="collapse-item text-dark"
              to="/assignsubjectoclass"
            >
              Asign Subject
            </NavLink>
            <div className="collapse-divider"></div>

            <NavLink
              className="collapse-item text-dark"
              to="/assignteachertoclass"
            >
              Asign Class Teacher
            </NavLink>
            <NavLink className="collapse-item text-dark" to="/classtimetable">
              Class Timetable
            </NavLink>
          </div>
        </div>
      </li>

      <li className="nav-item">
        <NavLink
          className="nav-link collapsed"
          to="#"
          data-toggle="collapse"
          data-target="#collapseTermlyExams"
          aria-expanded="true"
          aria-controls="collapseTermlyExams"
        >
          <i className="fas fa-pen text-white"></i>
          <span>Termly Exam</span>
        </NavLink>
        <div
          id="collapseTermlyExams"
          className="collapse"
          aria-labelledby="headingTermlyExams"
          data-parent="#accordionSidebar"
        >
          <div className="bg-white py-2 collapse-inner rounded">
            <NavLink className="collapse-item text-dark" to="/exam">
              Exam
            </NavLink>
            <NavLink className="collapse-item text-dark" to="/examschedule">
              Exam Schedule
            </NavLink>
            <NavLink className="collapse-item text-dark" to="/markregister">
              Marks Register
            </NavLink>
            <div className="collapse-divider"></div>

            {/* <NavLink className="collapse-item text-dark" href="404.html">
              Marks Grade
            </NavLink> */}
            <NavLink className="collapse-item text-dark" to="/examresult">
              Exams Result
            </NavLink>
          </div>
        </div>
      </li>

      <li className="nav-item">
        <NavLink
          className="nav-link collapsed"
          to="#"
          data-toggle="collapse"
          data-target="#collapseCummalativeExam"
          aria-expanded="true"
          aria-controls="collapseCummalativeExam"
        >
          <i className="fas fa-pen text-white"></i>
          <span>Cummulative Exam</span>
        </NavLink>
        <div
          id="collapseCummalativeExam"
          className="collapse"
          aria-labelledby="headingCummalativeExam"
          data-parent="#accordionSidebar"
        >
          <div className="bg-white py-2 collapse-inner rounded">
            <NavLink className="collapse-item text-dark" href="login.html">
              Cumullative
            </NavLink>

            <NavLink className="collapse-item text-dark" href="register.html">
              Cullative Schedule
            </NavLink>
            <NavLink
              className="collapse-item text-dark"
              href="forgot-password.html"
            >
              Cullative Register
            </NavLink>
            <div className="collapse-divider"></div>
          </div>
        </div>
      </li>
      <li className="nav-item">
        <NavLink
          className="nav-link collapsed"
          to="#"
          data-toggle="collapse"
          data-target="#collapseEntranceExam"
          aria-expanded="true"
          aria-controls="collapseEntranceExam"
        >
          <i className="fas fa-pen text-white"></i>
          <span>Entrance Exam</span>
        </NavLink>
        <div
          id="collapseEntranceExam"
          className="collapse"
          aria-labelledby="headingEntranceExam"
          data-parent="#accordionSidebar"
        >
          <div className="bg-white py-2 collapse-inner rounded">
            <NavLink className="collapse-item text-dark" to="/entraceexam">
              Entrance Registration
            </NavLink>

            <div className="collapse-divider"></div>
          </div>
        </div>
      </li>

      <li className="nav-item">
        <NavLink
          className="nav-link collapsed"
          to="#"
          data-toggle="collapse"
          data-target="#collapseAttendance"
          aria-expanded="true"
          aria-controls="collapseAttendance"
        >
          <i class="fas fa-users text-white"></i>
          <span>Attendance</span>
        </NavLink>
        <div
          id="collapseAttendance"
          className="collapse"
          aria-labelledby="headingAttendance"
          data-parent="#accordionSidebar"
        >
          <div className="bg-white py-2 collapse-inner rounded">
            <NavLink className="collapse-item text-dark" to="/attendance">
              Student Attendance
            </NavLink>
            <NavLink className="collapse-item text-dark" to="/attendanceReport">
              Attendance Report
            </NavLink>
          </div>
        </div>
      </li>

      <li className="nav-item">
        <NavLink
          className="nav-link collapsed"
          to="#"
          data-toggle="collapse"
          data-target="#collapseSchFees"
          aria-expanded="true"
          aria-controls="collapseSchFees"
        >
          <i className="fas fa-dollar-sign text-white"></i>
          <span>School Fees Collection</span>
        </NavLink>
        <div
          id="collapseSchFees"
          className="collapse"
          aria-labelledby="headingSchFees"
          data-parent="#accordionSidebar"
        >
          <div className="bg-white py-2 collapse-inner rounded">
            <NavLink className="collapse-item text-dark" href="login.html">
              Collect Fees
            </NavLink>
            <NavLink className="collapse-item text-dark" href="register.html">
              Fees Report
            </NavLink>
          </div>
        </div>
      </li>
      <li className="nav-item">
        <NavLink
          className="nav-link collapsed"
          to="#"
          data-toggle="collapse"
          data-target="#collapseHomeWork"
          aria-expanded="true"
          aria-controls="collapseHomeWork"
        >
          <i className="fas fa-home text-white"></i>
          <span>Home work</span>
        </NavLink>
        <div
          id="collapseHomeWork"
          className="collapse"
          aria-labelledby="headingHomeWork"
          data-parent="#accordionSidebar"
        >
          <div className="bg-white py-2 collapse-inner rounded">
            <NavLink className="collapse-item text-dark" href="login.html">
              Homework
            </NavLink>
            <NavLink className="collapse-item text-dark" href="register.html">
              Homework Report
            </NavLink>
          </div>
        </div>
      </li>
      <li className="nav-item">
        <NavLink
          className="nav-link collapsed"
          to="#"
          data-toggle="collapse"
          data-target="#collapseCommunication"
          aria-expanded="true"
          aria-controls="collapseCommunication"
        >
          <i className="fas fa-microphone text-white"></i>
          <span>Communication</span>
        </NavLink>
        <div
          id="collapseCommunication"
          className="collapse"
          aria-labelledby="headingCommunication"
          data-parent="#accordionSidebar"
        >
          <div className="bg-white py-2 collapse-inner rounded">
            <NavLink className="collapse-item text-dark" href="login.html">
              Noticed Board
            </NavLink>
          </div>
        </div>
      </li>
      <li className="nav-item">
        <NavLink
          className="nav-link collapsed"
          to="#"
          data-toggle="collapse"
          data-target="#collapseProfile"
          aria-expanded="true"
          aria-controls="collapseProfile"
        >
          <i className="fas fa-user text-white"></i>
          <span>Profile</span>
        </NavLink>
        <div
          id="collapseProfile"
          className="collapse"
          aria-labelledby="headingProfile"
          data-parent="#accordionSidebar"
        >
          <div className="bg-white py-2 collapse-inner rounded">
            <NavLink className="collapse-item text-dark" to="/view-profile">
              View
            </NavLink>
            <NavLink className="collapse-item text-dark" to="/update-profile">
              Update
            </NavLink>
            {/* <NavLink
              className="collapse-item text-dark"
              href="forgot-password.html"
            >
              Change Password
            </NavLink> */}
            <div className="collapse-divider"></div>
          </div>
        </div>
      </li>
      <li className="nav-item">
        <NavLink className="nav-link" to="/setting">
          <i class="fas fa-cogs text-white"></i>
          <span>Setting</span>
        </NavLink>
      </li>
    </ul>
  );
}

export default AdminSideBarItems;
