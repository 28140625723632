import React, { useContext, useState, useEffect } from "react";
import SideBar from "../../Components/SideBar";
import ReactPaginate from "react-paginate";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import { useClassesQuery } from "../../hooks/classHook";
import { Store } from "../../Store";
import { NavLink } from "react-router-dom";

const ITEMS_PER_PAGE = 10;

function ClassAndSubject() {
  const { data: classes, isPending: isLoading } = useClassesQuery();
  const {
    state: { userInfo },
  } = useContext(Store);

  const [subjects, setSubjects] = useState([]);

  useEffect(() => {
    if (classes && userInfo) {
      // Filter classes assigned to the logged-in teacher
      const teacherClasses = classes.filter((cls) =>
        JSON.parse(cls.teachers || "[]").some(
          (teacher) => teacher.email === userInfo.user
        )
      );

      // Extract subjects and corresponding class names
      const teacherSubjects = [];
      teacherClasses.forEach((cls) => {
        const subjectsInClass = JSON.parse(cls.subjects || "[]");
        subjectsInClass.forEach((subject) => {
          teacherSubjects.push({
            subject: subject.name,
            class: cls.Name, // Class name
            date: new Date().toLocaleDateString(), // Placeholder for created date
          });
        });
      });

      setSubjects(teacherSubjects);
    }
  }, [classes, userInfo]);

  const [currentPage, setCurrentPage] = useState(0);

  // Calculate the data to display on the current page
  const startOffset = currentPage * ITEMS_PER_PAGE;
  const endOffset = startOffset + ITEMS_PER_PAGE;
  const currentItems = subjects.slice(startOffset, endOffset);

  const handlePageClick = (event) => {
    setCurrentPage(event.selected);
  };

  const boxStyle = {
    borderTop: "3px solid #3c8dbc",
  };

  return (
    <SideBar>
      <div className="container-fluid">
        <div className="card w-100 px-2 mb-3" style={boxStyle}>
          <div className="box-header with-border">
            <h3 className="box-title py-2">Class & Subjects</h3>
          </div>

          <div className="row" style={{ overflowX: "auto" }}>
            {isLoading ? <p>Loading...</p> : ""}
            <table className="table table-hover">
              <thead>
                <tr>
                  <th className="text-uppercase">Subject</th>
                  <th className="text-uppercase">Class</th>
                  <th className="text-uppercase">Created Date</th>
                  <th className="text-uppercase">Action</th>
                </tr>
              </thead>
              <tbody>
                {currentItems.map((item, index) => (
                  <tr key={index}>
                    <td>
                      <NavLink to="/">
                        <b>{item.subject}</b>
                      </NavLink>
                    </td>
                    <td>
                      <b>{item.class}</b>
                    </td>
                    <td>{item.date}</td>
                    <td className="align-middle">
                      <NavLink className="btn btn-primary" to="/">
                        My Class Timetable
                      </NavLink>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          <ReactPaginate
            previousLabel={
              <div className="pagination-label">
                <FaArrowLeft />
              </div>
            }
            nextLabel={
              <div className="pagination-label">
                <FaArrowRight />
              </div>
            }
            breakLabel="..."
            pageCount={Math.ceil(subjects.length / ITEMS_PER_PAGE)}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            onPageChange={handlePageClick}
            containerClassName="pagination-container pagination justify-content-end mt-3"
            pageClassName="page-item"
            pageLinkClassName="page-link"
            previousClassName="page-item"
            previousLinkClassName="page-link"
            nextClassName="page-item"
            nextLinkClassName="page-link"
            breakClassName="page-item"
            breakLinkClassName="page-link"
            activeClassName="active"
          />
        </div>
      </div>
    </SideBar>
  );
}

export default ClassAndSubject;
