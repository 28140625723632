import React, { useContext } from "react";
import { useClassesQuery } from "../../hooks/classHook";
import { useStudentsQuery } from "../../hooks/studentHook";
import { Store } from "../../Store";

function TeacherItems() {
  // const { data: subject, isPending, isError, error } = useSubjectQuery();
  const { data: classes } = useClassesQuery();
  const { data: students } = useStudentsQuery();

  const {
    state: { userInfo },
  } = useContext(Store);

  // Filter classes where the logged-in teacher is included
  const teacherClasses = classes?.filter((cls) =>
    JSON.parse(cls.teachers || "[]").some(
      (teacher) => teacher.email === userInfo.user
    )
  );

  // console.log("Teacher's Classes:", teacherClasses);

  // Filter students who belong to the teacher's classes
  const studentsInTeacherClasses = students?.filter((student) =>
    teacherClasses?.some((cls) => cls.Name === student.class)
  );

  // Calculate the number of classes
  const numberOfClasses = teacherClasses?.length || 0;

  // Calculate the total number of students in those classes
  const numberOfStudents = studentsInTeacherClasses?.length || 0;

  // Extract subjects from the teacher's classes
  let subjects = [];
  teacherClasses?.forEach((cls) => {
    subjects = subjects.concat(JSON.parse(cls.subjects || "[]"));
  });

  return (
    <div className="row">
      {/*  <!-- students --> */}
      <div className="col-xl-3 col-md-6 mb-4">
        <div className="card border-left-danger bg-info shadow h-100 py-2">
          <div className="card-body">
            <div className="row no-gutters align-items-center">
              <div className="col mr-2">
                <div className="text-xs font-weight-bold text-white text-uppercase mb-1">
                  STUDENTS
                </div>
                <div className="row no-gutters align-items-center">
                  <div className="col-auto">
                    <div className="h5 mb-0 mr-3 font-weight-bold text-white">
                      {numberOfStudents}
                    </div>
                  </div>
                  <div className="col">
                    <div className="progress">
                      <div
                        className="progress-bar bg-success"
                        style={{ width: "70%" }}
                      ></div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-auto">
                <i className="fas fa-users fa-2x text-white"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* classes */}
      <div className="col-xl-3 col-md-6 mb-4">
        <div className="card border-left-success bg-warning shadow h-100 py-2">
          <div className="card-body">
            <div className="row no-gutters align-items-center">
              <div className="col mr-2">
                <div className="text-xs font-weight-bold text-white text-uppercase mb-1">
                  CLASSES
                </div>
                <div className="h5 mb-0 font-weight-bold text-white">
                  {numberOfClasses}
                </div>
              </div>
              <div className="col-auto">
                <i className="fas fa-home fa-2x text-white"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* subjects */}
      <div className="col-xl-3 col-md-6 mb-4">
        <div className="card border-left-secondary bg-success shadow h-100 py-2">
          <div className="card-body">
            <div className="row no-gutters align-items-center">
              <div className="col mr-2">
                <div className="text-xs font-weight-bold text-white text-uppercase mb-1">
                  SUBJECTS
                </div>
                <div className="h5 mb-0 font-weight-bold text-white">
                  {subjects.length}
                </div>
              </div>
              <div className="col-auto">
                <i className="fas fa-book fa-2x text-white"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* notification */}
      <div className="col-xl-3 col-md-6 mb-4">
        <div className="card border-left-warning bg-primary shadow h-100 py-2">
          <div className="card-body">
            <div className="row no-gutters align-items-center">
              <div className="col mr-2">
                <div className="text-xs font-weight-bold text-white text-uppercase mb-1">
                  NOTIFICATION BOARD
                </div>
                <div className="h5 mb-0 font-weight-bold text-white">1</div>
              </div>
              <div className="col-auto">
                <i className="fas fa-eye fa-2x text-white"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TeacherItems;
