/** @format */

import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import apiClient from "../apiClient";

// Hook for fetching all classes
export const useClassesQuery = () => {
  return useQuery({
    queryKey: ["classes"],
    queryFn: async () => {
      const { data } = await apiClient.get("api/classes/all-classes");
      return data;
    },
  });
};

// Hook for adding a new class
export const useAddClassMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (newClass) =>
      (await apiClient.post("api/classes", newClass)).data,
    onSuccess: () => {
      queryClient.invalidateQueries(["classes"]);
    },
  });
};

// assign subject to class
export const useAssignSubjectsToClassMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({ classId, subjectIds }) =>
      (
        await apiClient.post(`api/classes/${classId}/assign-subjects`, {
          subjectIds,
        })
      ).data,
    onSuccess: () => {
      queryClient.invalidateQueries(["classes"]);
    },
  });
};

// Hook for editing a class
export const useEditClassMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({ id, updatedClass }) =>
      (await apiClient.put(`api/classes/${id}`, updatedClass)).data,
    onSuccess: () => {
      queryClient.invalidateQueries(["classes"]);
    },
  });
};

// Hook for deleting a class
export const useDeleteClassMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (id) =>
      (await apiClient.delete(`api/classes/${id}`)).data,
    onSuccess: () => {
      queryClient.invalidateQueries(["classes"]);
    },
  });
};
