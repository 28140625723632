import React, { useState } from "react";
import SideBar from "../Components/SideBar";
import { useClassesQuery } from "../hooks/classHook";
import { useSubjectQuery } from "../hooks/subject";

const ExamScheduleForm = () => {
  // Fetch classes and subjects
  const { data: classes } = useClassesQuery();
  const { data: subjects } = useSubjectQuery();

  // Initialize state for schedules
  const [schedules, setSchedules] = useState([
    {
      examDate: "2023-11-28",
      startTime: "08:15",
      endTime: "11:15",
      roomNumber: "2A",
      fullMarks: 100,
      passMarks: 50,
      goodMarks: 75,
      failMarks: 39,
      goodFirst: 59,
    },
    // Additional schedule objects can be added here
  ]);

  const handleChange = (index, event) => {
    const { name, value } = event.target;
    const updatedSchedules = [...schedules];
    updatedSchedules[index] = { ...updatedSchedules[index], [name]: value };
    setSchedules(updatedSchedules);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    // Handle form submission
    console.log(schedules);
  };

  return (
    <SideBar>
      <div className="container-fluid">
        <div className="row">
          <div className="card px-4">
            <div className="box box-primary">
              <div className="box-header with-border">
                <h3 className="box-title">Search Exam Schedule</h3>
              </div>
              <form action="" method="get" encType="multipart/form-data">
                <div className="box-body">
                  <div className="row">
                    <div className="form-group col-md-4 pb-3">
                      <select className="form-control" name="exam_id" required>
                        <option value="">Select Exam</option>
                        <option value="5" selected>
                          FIRST TERM 2023/2024
                        </option>
                        <option value="12">FIRST TERM 2024/2025</option>
                        <option value="6">SECOND TERM 2023/2024</option>
                        <option value="8">THIRD TERM 2023/2024</option>
                      </select>
                    </div>
                    <div className="form-group col-md-4">
                      <select className="form-control" name="class_id" required>
                        <option value="">Select Class</option>
                        {classes &&
                          classes.map((classItem) => (
                            <option key={classItem.SN} value={classItem.SN}>
                              {classItem.Name}
                            </option>
                          ))}
                      </select>
                    </div>
                    <div className="form-group col-md-4 pt-1">
                      <button className="btn btn-primary" type="submit">
                        Search
                      </button>
                      <button className="btn btn-success ml-2">Reset</button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <form onSubmit={handleSubmit}>
              <input
                type="hidden"
                name="_token"
                value="uNDFbpDrW1kRgvDXqCgQkOOkQur4wM7toYVCzzbT"
              />
              <input type="hidden" name="exam_id" value="5" />
              <input type="hidden" name="class_id" value="10" />

              <div className="box">
                <div className="box-header">
                  <h3 className="box-title">Exam Schedule</h3>
                </div>
                <div className="box-body table-responsive no-padding">
                  <table className="table table-hover">
                    <thead>
                      <tr>
                        <th className="text-uppercase text-center text-secondary text-xxs font-weight-bolder opacity-7 ps-2">
                          Subject
                        </th>
                        <th className="text-uppercase text-center text-secondary text-xxs font-weight-bolder opacity-7 ps-2">
                          Date
                        </th>
                        <th className="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                          Start
                        </th>
                        <th className="text-uppercase text-center text-secondary text-xxs font-weight-bolder opacity-7">
                          End
                        </th>
                        <th className="text-uppercase text-center text-secondary text-xxs font-weight-bolder opacity-7">
                          Venue
                        </th>
                        <th className="text-uppercase text-center text-secondary text-xxs font-weight-bolder opacity-7">
                          Full
                        </th>
                        <th className="text-uppercase text-center text-secondary text-xxs font-weight-bolder opacity-7">
                          Pass
                        </th>
                        <th className="text-uppercase text-center text-secondary text-xxs font-weight-bolder opacity-7">
                          Excel
                        </th>
                        <th className="text-uppercase text-center text-secondary text-xxs font-weight-bolder opacity-7">
                          Fail
                        </th>
                        <th className="text-uppercase text-center text-secondary text-xxs font-weight-bolder opacity-7">
                          Good
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {subjects &&
                        subjects.map((subject) => (
                          <tr key={subject.subject_id}>
                            <td>{subject.subject_name}</td>
                            <td>
                              <input
                                type="date"
                                className="form-control"
                                style={{
                                  border: "1px solid lightgray",
                                  padding: "5px",
                                }}
                                name={`schedule[${subject.subject_id}][exam_date]`}
                                value={schedules[0]?.examDate || ""}
                                onChange={(e) => handleChange(0, e)}
                              />
                            </td>
                            <td>
                              <input
                                type="time"
                                className="form-control"
                                style={{
                                  border: "1px solid lightgray",
                                  padding: "5px",
                                }}
                                name={`schedule[${subject.subject_id}][start_time]`}
                                value={schedules[0]?.startTime || ""}
                                onChange={(e) => handleChange(0, e)}
                              />
                            </td>
                            <td>
                              <input
                                type="time"
                                className="form-control"
                                style={{
                                  border: "1px solid lightgray",
                                  padding: "5px",
                                }}
                                name={`schedule[${subject.subject_id}][end_time]`}
                                value={schedules[0]?.endTime || ""}
                                onChange={(e) => handleChange(0, e)}
                              />
                            </td>
                            <td>
                              <input
                                type="text"
                                className="form-control"
                                style={{ border: "1px solid lightgray" }}
                                name={`schedule[${subject.subject_id}][room_number]`}
                                value={schedules[0]?.roomNumber || ""}
                                onChange={(e) => handleChange(0, e)}
                              />
                            </td>
                            <td>
                              <input
                                type="text"
                                className="form-control"
                                style={{ border: "1px solid lightgray" }}
                                name={`schedule[${subject.subject_id}][full_marks]`}
                                value={schedules[0]?.fullMarks || ""}
                                onChange={(e) => handleChange(0, e)}
                              />
                            </td>
                            <td>
                              <input
                                type="text"
                                className="form-control"
                                style={{ border: "1px solid lightgray" }}
                                name={`schedule[${subject.subject_id}][pass_marks]`}
                                value={schedules[0]?.passMarks || ""}
                                onChange={(e) => handleChange(0, e)}
                              />
                            </td>
                            <td>
                              <input
                                type="text"
                                className="form-control"
                                style={{ border: "1px solid lightgray" }}
                                name={`schedule[${subject.subject_id}][good_marks]`}
                                value={schedules[0]?.goodMarks || ""}
                                onChange={(e) => handleChange(0, e)}
                              />
                            </td>
                            <td>
                              <input
                                type="text"
                                className="form-control"
                                style={{ border: "1px solid lightgray" }}
                                name={`schedule[${subject.subject_id}][fail_marks]`}
                                value={schedules[0]?.failMarks || ""}
                                onChange={(e) => handleChange(0, e)}
                              />
                            </td>
                            <td>
                              <input
                                type="text"
                                className="form-control"
                                style={{ border: "1px solid lightgray" }}
                                name={`schedule[${subject.subject_id}][good_first]`}
                                value={schedules[0]?.goodFirst || ""}
                                onChange={(e) => handleChange(0, e)}
                              />
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
                <div className="box-footer d-flex justify-content-end pb-5">
                  <button type="submit" className="btn btn-primary">
                    Submit
                  </button>
                  {/* <button
                    type="button"
                    className="btn btn-secondary ml-2"
                    onClick={() =>
                      setSchedules([
                        ...schedules,
                        {
                          examDate: "2023-11-28",
                          startTime: "08:15",
                          endTime: "11:15",
                          roomNumber: "2A",
                          fullMarks: 100,
                          passMarks: 50,
                          goodMarks: 75,
                          failMarks: 39,
                          goodFirst: 59,
                        },
                      ])
                    }
                  >
                    Add Schedule
                  </button> */}
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </SideBar>
  );
};

export default ExamScheduleForm;
