import { useContext } from "react";
import { Store } from "../../Store";

import AdminMarkRegister from "../../Components/AdminMarkRegister";
import TeacherMarkRegister from "../../Components/TeacherMarkRegister";

const MarkRegister = () => {
  const {
    state: { userInfo },
  } = useContext(Store);

  return (
    <>
      {userInfo && userInfo.role === "admin" ? (
        <AdminMarkRegister />
      ) : userInfo && userInfo.role === "teacher" ? (
        <TeacherMarkRegister />
      ) : (
        ""
      )}
    </>
  );
};

export default MarkRegister;
