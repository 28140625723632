import React, { useContext, useState } from "react";
import SideBar from "../../Components/SideBar";
import ReactPaginate from "react-paginate";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import { Store } from "../../Store";
import { useClassesQuery } from "../../hooks/classHook";
import { useStudentsQuery } from "../../hooks/studentHook";

const ITEMS_PER_PAGE = 10;
const TeacherStudent = () => {
  const { data: classes } = useClassesQuery();
  const { data: students } = useStudentsQuery();

  const {
    state: { userInfo },
  } = useContext(Store);

  // Filter classes where the logged-in teacher is included
  const teacherClasses = classes?.filter((cls) =>
    JSON.parse(cls.teachers || "[]").some(
      (teacher) => teacher.email === userInfo.user
    )
  );

  // console.log("Teacher's Classes:", teacherClasses);

  // Filter students who belong to the teacher's classes
  const studentsInTeacherClasses = students?.filter((student) =>
    teacherClasses?.some((cls) => cls.Name === student.class)
  );

  // Extract subjects from the teacher's classes
  let subjects = [];
  teacherClasses?.forEach((cls) => {
    subjects = subjects.concat(JSON.parse(cls.subjects || "[]"));
  });

  const [currentPage, setCurrentPage] = useState(0);

  // Calculate the data to display on the current page
  const startOffset = currentPage * ITEMS_PER_PAGE;
  const endOffset = startOffset + ITEMS_PER_PAGE;
  const currentItems = studentsInTeacherClasses.slice(startOffset, endOffset);

  const handlePageClick = (event) => {
    setCurrentPage(event.selected);
  };
  const boxStyle = {
    borderTop: "3px solid #3c8dbc",
  };
  return (
    <SideBar>
      <div className="container-fluid">
        <div class="card w-100 px-2 mb-3" style={boxStyle}>
          <div class="box-header with-border">
            <h3 class="box-title py-2">My Student</h3>
          </div>

          <div className="row" style={{ overflowX: "auto" }}>
            {/* {isPending ? <CgSpinner size={25} /> : ""} */}
            <table className="table table-hover">
              <thead>
                <tr>
                  <th className="text-uppercase" style={{ color: "black" }}>
                    SN
                  </th>
                  <th className="text-uppercase" style={{ color: "black" }}>
                    Student Record
                  </th>
                  <th className="text-uppercase" style={{ color: "black" }}>
                    Bio Data
                  </th>
                  <th className="text-uppercase" style={{ color: "black" }}>
                    Registered On
                  </th>
                  <th
                    className="text-uppercase text-center"
                    style={{ color: "black" }}
                  >
                    Action
                  </th>
                </tr>
              </thead>
              <tbody>
                {currentItems.map((student, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>
                      <span className="text-primary">{student.name}</span>
                      <p style={{ color: "black" }}>
                        Admission No.:{" "}
                        <span className="text-secondary">
                          SFS/{new Date(student.updated_at).getFullYear()}/
                          {student.userid}
                        </span>
                      </p>
                      <p style={{ color: "black" }}>
                        Class:{" "}
                        <span className="text-secondary">{student.class}</span>
                      </p>
                    </td>
                    <td>
                      <p style={{ color: "black" }}>
                        DOB:{" "}
                        <span className="text-secondary">
                          {new Date(student.date_of_birth).toLocaleDateString(
                            "en-US",
                            {
                              year: "2-digit",
                              month: "numeric",
                              day: "numeric",
                            }
                          )}
                        </span>
                      </p>
                      <p style={{ color: "black" }}>
                        Gender:{" "}
                        <span className="text-secondary">{student.gender}</span>
                      </p>
                      <p style={{ color: "black" }}>
                        Religion:{" "}
                        <span className="text-secondary">
                          {student.religion}
                        </span>
                      </p>
                    </td>
                    <td>
                      <span className="text-secondary">
                        {new Date(student.updated_at).toLocaleDateString(
                          "en-US",
                          {
                            year: "2-digit",
                            month: "numeric",
                            day: "numeric",
                          }
                        )}
                      </span>
                    </td>
                    <td className="d-flex justify-content-center">
                      <button
                        className="btn btn-primary btn-sm mr-2"
                        style={{ color: "black" }}
                      >
                        Psycho
                      </button>
                      <button
                        className="btn btn-warning btn-sm "
                        style={{ color: "black" }}
                      >
                        Chat
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <ReactPaginate
            previousLabel={
              <div className="pagination-label">
                <FaArrowLeft />
              </div>
            }
            nextLabel={
              <div className="pagination-label">
                <FaArrowRight />
              </div>
            }
            breakLabel="..."
            pageCount={Math.ceil(
              studentsInTeacherClasses.length / ITEMS_PER_PAGE
            )}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            onPageChange={handlePageClick}
            containerClassName="pagination-container pagination justify-content-end mt-3"
            pageClassName="page-item"
            pageLinkClassName="page-link"
            previousClassName="page-item"
            previousLinkClassName="page-link"
            nextClassName="page-item"
            nextLinkClassName="page-link"
            breakClassName="page-item"
            breakLinkClassName="page-link"
            activeClassName="active"
          />
        </div>
      </div>
    </SideBar>
  );
};

export default TeacherStudent;
