import React, { useState } from "react";
import toast from "react-hot-toast";
import SideBar from "../Components/SideBar";
import { useClassesQuery } from "../hooks/classHook";
import { useTeachersQuery } from "../hooks/teacherHook";

const AssignTeacherToClass = () => {
  const [classId, setClassId] = useState("");
  const [selectedTeachers, setSelectedTeachers] = useState([]);
  // const { mutate: assignSubjectsToClass } = useAssignSubjectsToClassMutation();

  const { data: teachers } = useTeachersQuery();
  const { data: classes } = useClassesQuery();

  const handleClassChange = (e) => {
    setClassId(e.target.value);
  };

  const handleSubjectChange = (e) => {
    const teacherId = e.target.value;
    const teacherName = e.target.dataset.teacherName;
    const teacherEmail = e.target.dataset.teacherEmail;

    setSelectedTeachers((prevTeachers) => {
      const teacherIdString = teacherId.toString();
      const isSelected = prevTeachers.some(
        (teacher) => teacher.id === teacherIdString
      );
      if (isSelected) {
        return prevTeachers.filter((teacher) => teacher.id !== teacherIdString);
      } else {
        return [
          ...prevTeachers,
          { id: teacherIdString, name: teacherName, email: teacherEmail },
        ];
      }
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (classId && selectedTeachers.length > 0) {
      try {
        const response = await fetch(
          `http://localhost:3000/api/classes/${classId}/assign-teachers`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              teachers: selectedTeachers, // Send an array of objects with id and name
            }),
          }
        );

        const data = await response.json();

        if (response.ok) {
          toast.success("Teachers assigned successfully");
        } else {
          toast.error(`Error: ${data.message}`);
        }
      } catch (error) {
        toast.error(`Error: ${error.message}`);
      }
    } else {
      toast.error("Please select a class and at least one Teacher.");
    }
  };

  const boxStyle = {
    borderTop: "3px solid #3c8dbc",
  };

  return (
    <SideBar>
      <div className="container-fluid">
        <div className="card w-100 py-2 px-2 mb-3" style={boxStyle}>
          <div className="box-header with-border">
            <h3 className="box-title text-black">Assign Class Teacher</h3>
          </div>
          <form onSubmit={handleSubmit} className="px-2">
            <div className="row w-100">
              <label className="w-100">
                Select Class <span style={{ color: "red" }}>*</span>
                <select
                  className="form-control w-100"
                  value={classId}
                  onChange={handleClassChange}
                >
                  <option value="">Select a class</option>
                  {classes &&
                    classes.map((classItem) => (
                      <option key={classItem.SN} value={classItem.SN}>
                        {classItem.Name}
                      </option>
                    ))}
                </select>
              </label>
            </div>
            <div className="box-body">
              <div className="row" style={{ padding: "10px" }}>
                <div className="form-group">
                  <label>
                    Class Teacher Name <span style={{ color: "red" }}>*</span>
                  </label>
                  {teachers &&
                    teachers.map((teacher) => (
                      <div key={teacher.userid}>
                        <label style={{ fontWeight: "normal" }}>
                          <input
                            style={{
                              border: "solid 1px lightgray",
                              padding: "5px",
                            }}
                            type="checkbox"
                            value={teacher.userid}
                            data-teacher-name={teacher.username}
                            data-teacher-email={teacher.email}
                            checked={selectedTeachers.some(
                              (sub) => sub.id === teacher.userid.toString()
                            )}
                            onChange={handleSubjectChange}
                          />
                          <span>{teacher.username}</span>
                        </label>
                      </div>
                    ))}
                </div>
              </div>
              <div className="card-footer">
                <button type="submit" className="btn btn-primary">
                  Submit
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </SideBar>
  );
};

export default AssignTeacherToClass;
