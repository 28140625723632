import React, { useState } from "react";
import SideBar from "../Components/SideBar";
import { useGetAttendanceQuery } from "../hooks/admin";
import { useClassesQuery } from "../hooks/classHook";

const AttendanceReport = () => {
  const { data: classes } = useClassesQuery();
  const [formData, setFormData] = useState({
    class_id: "",
    student_name: "",
    attendance_date: "",
  });

  const { data: attendance } = useGetAttendanceQuery();

  // Handle form input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  // Filter attendance based on class_id from formData
  const filteredAttendance = attendance?.filter((record) => {
    const matchesClass = formData.class_id
      ? record.class === formData.class_id
      : true;
    const matchesName = formData.student_name
      ? record.student_name
          .toLowerCase()
          .includes(formData.student_name.toLowerCase())
      : true;
    const matchesDate = formData.attendance_date
      ? new Date(record.date).toLocaleDateString() ===
        new Date(formData.attendance_date).toLocaleDateString()
      : true;

    return matchesClass && matchesName && matchesDate;
  });

  // Debugging logs
  console.log("Form Data:", formData);
  console.log("Filtered Attendance:", filteredAttendance);

  return (
    <SideBar>
      <section className="container-fluid">
        <div className="card p-4">
          <div className="row">
            <div className="col-md-12">
              <div className="box box-primary">
                <div className="box-header with-border">
                  <h3 className="box-title">Search Attendance Report</h3>
                </div>
                <form onSubmit={handleSubmit}>
                  <div className="box-body">
                    <div className="row">
                      <div className="form-group col-md-3">
                        <label htmlFor="class_id">Class</label>
                        <select
                          className="form-control"
                          name="class_id"
                          value={formData.class_id}
                          onChange={handleInputChange}
                        >
                          <option value="">Select Class</option>
                          {classes?.map((classItem) => (
                            <option key={classItem.id} value={classItem.id}>
                              {classItem.Name}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className="form-group col-md-3">
                        <label htmlFor="student_name">Student Name</label>
                        <input
                          type="text"
                          className="form-control"
                          name="student_name"
                          value={formData.student_name}
                          onChange={handleInputChange}
                          placeholder="Student Name"
                        />
                      </div>
                      <div className="form-group col-md-3">
                        <label htmlFor="attendance_date">Attendance Date</label>
                        <input
                          type="date"
                          className="form-control"
                          name="attendance_date"
                          value={formData.attendance_date}
                          onChange={handleInputChange}
                        />
                      </div>
                      <div
                        className="form-group col-md-3"
                        style={{ paddingTop: "24px", paddingBottom: "20px" }}
                      >
                        <button className="btn btn-primary" type="submit">
                          Search
                        </button>
                        <button
                          className="btn btn-success"
                          type="reset"
                          style={{ marginLeft: "10px" }}
                          onClick={() =>
                            setFormData({
                              class_id: "",
                              student_name: "",
                              attendance_date: "",
                            })
                          }
                        >
                          Reset
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="box">
                <div className="box-header">
                  <h3 className="box-title">Attendance List</h3>
                </div>
                <div
                  className="box-body table-responsive  no-padding"
                  style={{ overflowX: "auto" }}
                >
                  <table className="table table-bordered table-hover">
                    <thead>
                      <tr>
                        <th className="text-center">UserID</th>
                        <th>Student Name</th>
                        <th className="text-center">Class Name</th>
                        <th className="text-center">Attendance</th>
                        <th className="text-center">Day</th>
                        <th className="text-center">Attendance Date</th>
                        <th className="text-center">Created By</th>
                      </tr>
                    </thead>
                    <tbody>
                      {filteredAttendance?.length > 0 ? (
                        filteredAttendance.map((record) => (
                          <tr key={record.userId}>
                            <td className="text-center">{record.id}</td>
                            <td>{record.student_name}</td>
                            <td className="text-center">{record.class}</td>
                            <td className="text-center">
                              {record.attendance_status}
                            </td>
                            <td className="text-center">
                              {new Date(record.date).toLocaleDateString(
                                "en-US",
                                {
                                  weekday: "long",
                                }
                              )}
                            </td>
                            <td className="text-center">
                              {new Date(record.date).toLocaleDateString(
                                "en-US",
                                {
                                  year: "2-digit",
                                  month: "numeric",
                                  day: "numeric",
                                }
                              )}
                            </td>
                            <td className="text-center">
                              {record.collected_by}
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan="7" className="text-center">
                            No records found.
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </SideBar>
  );
};

export default AttendanceReport;
