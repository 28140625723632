import React, { useState } from "react";
import SideBar from "../Components/SideBar";
import { useClassesQuery } from "../hooks/classHook";
import { useSubjectQuery } from "../hooks/subject";

function ClassTimetable() {
  const { data: subjects } = useSubjectQuery();
  const { data: classes } = useClassesQuery();

  // Initialize state
  const [formData, setFormData] = useState({
    class_id: "",
    subject_id: "",
    timetable: {},
  });

  const daysOfWeek = [
    { id: 1, name: "Monday" },
    { id: 2, name: "Tuesday" },
    { id: 3, name: "Wednesday" },
    { id: 4, name: "Thursday" },
    { id: 5, name: "Friday" },
  ];

  const handleSelectChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleInputChange = (e, dayId) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      timetable: {
        ...prevData.timetable,
        [dayId]: {
          ...prevData.timetable[dayId],
          [name]: value,
        },
      },
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const { class_id, subject_id, timetable } = formData;
    const timetableEntries = [];

    // Convert timetable object to an array of entries
    Object.keys(timetable).forEach((dayId) => {
      const entry = timetable[dayId];
      if (entry.start_time && entry.end_time && entry.room_number) {
        timetableEntries.push({
          class_id,
          subject_id,
          day_of_week: daysOfWeek[dayId - 1].name, // Convert dayId to day name
          start_time: entry.start_time,
          end_time: entry.end_time,
          room_number: entry.room_number,
        });
      }
    });

    if (timetableEntries.length === 0) {
      alert("Please fill in at least one timetable entry.");
      return;
    }

    try {
      const response = await fetch(
        "http://localhost:3000/api/classes/timetable",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            timetableEntries,
          }),
        }
      );

      if (response.status === 201) {
        alert("Timetable added successfully");
      }
    } catch (error) {
      console.error("Error adding timetable:", error);
      alert("Error adding timetable");
    }
  };

  return (
    <SideBar>
      <div className="container">
        <div className="box box-primary">
          <div className="box-header with-border">
            <h3 className="box-title">Search Class Timetable</h3>
          </div>
          <form onSubmit={handleSubmit}>
            <div className="box-body">
              <div className="row">
                <div className="form-group col-md-4">
                  <select
                    className="form-control"
                    name="class_id"
                    value={formData.class_id}
                    onChange={handleSelectChange}
                    required
                  >
                    <option value="">Select Class</option>
                    {classes &&
                      classes.map((classItem) => (
                        <option key={classItem.SN} value={classItem.SN}>
                          {classItem.Name}
                        </option>
                      ))}
                  </select>
                </div>
                <div className="form-group col-md-4">
                  <select
                    className="form-control"
                    name="subject_id"
                    value={formData.subject_id}
                    onChange={handleSelectChange}
                    required
                  >
                    <option value="">Select Subject</option>
                    {subjects &&
                      subjects.map((subject) => (
                        <option
                          key={subject.subject_id}
                          value={subject.subject_id}
                        >
                          {subject.subject_name}
                        </option>
                      ))}
                  </select>
                </div>
              </div>
            </div>
          </form>
        </div>
        <div className="box" style={{ padding: "10px" }}>
          <div className="box-header">
            <h3 className="box-title">Class Timetable</h3>
          </div>
          <form onSubmit={handleSubmit}>
            <div className="box-body table-responsive no-padding">
              <table className="table table-hover">
                <thead>
                  <tr>
                    <th>Week Record</th>
                    <th className="text-center">Start Time</th>
                    <th className="text-center">End Time</th>
                    <th className="text-center">Room Number</th>
                  </tr>
                </thead>
                <tbody>
                  {daysOfWeek.map((day) => (
                    <tr key={day.id}>
                      <td>{day.name}</td>
                      <td>
                        <input
                          type="time"
                          name="start_time"
                          value={formData.timetable[day.id]?.start_time || ""}
                          onChange={(e) => handleInputChange(e, day.id)}
                          className="form-control"
                        />
                      </td>
                      <td>
                        <input
                          type="time"
                          name="end_time"
                          value={formData.timetable[day.id]?.end_time || ""}
                          onChange={(e) => handleInputChange(e, day.id)}
                          className="form-control"
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          name="room_number"
                          value={formData.timetable[day.id]?.room_number || ""}
                          onChange={(e) => handleInputChange(e, day.id)}
                          className="form-control"
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div className="card-footer">
              <button type="submit" className="btn btn-primary">
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
    </SideBar>
  );
}

export default ClassTimetable;
