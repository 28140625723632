/* eslint-disable jsx-a11y/alt-text */
import SideBar from "../Components/SideBar";

import ReactPaginate from "react-paginate";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import { useState } from "react";

import { CgSpinner } from "react-icons/cg";

import toast from "react-hot-toast";
import { useStudentsQuery } from "../hooks/studentHook";
import AddStudentModal from "../Components/AddStudentModal";

const ITEMS_PER_PAGE = 10;

function Students() {
  const [currentPage, setCurrentPage] = useState(0);
  const [editingStudent, setEditingStudent] = useState(null);
  const [showModal, setShowModal] = useState(false);

  const { data: students, isPending, isError, error } = useStudentsQuery();

  const handleShowModal = (term = null) => {
    setEditingStudent(term); // Set the editing term if provided
    setShowModal(true); // Show the modal
  };

  // Handle editing term
  const handleEditStudent = (term) => {
    handleShowModal(term); // Show the modal for editing
  };

  if (isPending) {
    return <CgSpinner size={25} />;
  }

  if (isError) {
    return toast.error(error.message);
  }

  if (!students || students.length === 0) {
    return <div>No students found.</div>;
  }

  // Calculate the data to display on the current page
  const startOffset = currentPage * ITEMS_PER_PAGE;
  const endOffset = startOffset + ITEMS_PER_PAGE;
  const currentItems = students.slice(startOffset, endOffset);

  const handlePageClick = (event) => {
    setCurrentPage(event.selected);
  };
  const boxStyle = {
    borderTop: "3px solid #3c8dbc",
  };
  const handleCloseModal = () => {
    setShowModal(false);
    setEditingStudent(null);
  };

  return (
    <SideBar>
      <div className="container-fluid">
        <div class="card w-100 py-2 px-2 mb-3" style={boxStyle}>
          <div class="box-header with-border">
            <h3 class="box-title text-black">Search Student</h3>
          </div>
          <form action="" method="get">
            <div class="box-body">
              <div class="row">
                <div class="form-group no-margin col-md-4">
                  <label for="exampleInputEmail1">Name</label>
                  <input
                    type="text"
                    value=""
                    name="name"
                    class="form-control"
                    placeholder="Search name"
                  />
                </div>
                <div class="form-group no-margin col-md-4">
                  <label for="exampleInputEmail1">Email</label>
                  <input
                    type="text"
                    value=""
                    name="name"
                    class="form-control"
                    placeholder="Search email"
                  />
                </div>
                <div
                  class="form-group no-margin col-md-4"
                  style={{ paddingTop: "33px" }}
                >
                  <button type="submit" class="btn btn-primary">
                    Search
                  </button>
                  <button type="submit" class="btn ml-2 btn-success">
                    Reset
                  </button>
                </div>
              </div>
            </div>
          </form>
          {/*  <!-- Content Row --> */}
          <div className="row" style={{ overflowX: "auto" }}>
            <div className="col-md-12">
              <div className="px-4 d-flex justify-content-between align-items-center">
                <p className="mb-0">Student List ({students?.length})</p>
                <button
                  className="btn btn-success btn-sm"
                  onClick={() => handleShowModal(null)}
                >
                  Add Student
                </button>
              </div>
            </div>
            {/* AddTeacherModal Component */}
            <AddStudentModal
              show={showModal}
              handleClose={handleCloseModal}
              editingStudent={editingStudent}
            />
            {isPending ? <CgSpinner size={25} /> : ""}
            <table className="table table-hover">
              <thead className="thead-light">
                <tr>
                  <th className="text-center">USERID</th>
                  <th className="text-center">PHOTO</th>
                  <th>USER NAME</th>
                  <th>CLASS</th>
                  <th className="text-center">LAST SEEN</th>
                  <th className="text-center">Action</th>
                </tr>
              </thead>
              <tbody>
                {currentItems.map((item) => (
                  <tr key={item.SN}>
                    <td className="text-center">{item.userid}</td>

                    <td className="  text-center">
                      {item.profile_pic ? (
                        <img
                          className="rounded-circle"
                          style={{ width: "50px", height: "50px" }}
                          src={item.profile_pic}
                        />
                      ) : (
                        <img
                          className="rounded-circle"
                          style={{ width: "50px", height: "50px" }}
                          src="img/undraw_profile.svg"
                        />
                      )}
                    </td>
                    <td>
                      <p>{item.username}</p>
                      <span
                        className={`badge ${
                          item.status === "Active" || "ACTIVE"
                            ? "bg-success text-white"
                            : "bg-secondary text-white"
                        }`}
                      >
                        {item.status}
                      </span>
                    </td>
                    <td>{item.class}</td>
                    <td className="text-center">{item.last_seen}</td>
                    <td className="d-flex justify-content-end">
                      <button
                        className="btn btn-success btn-sm text-black"
                        onClick={() => handleEditStudent(item)}
                      >
                        EDIT
                      </button>
                      <button className="btn btn-info btn-sm text-black ml-2">
                        CHART
                      </button>
                      <button
                        className="btn btn-warning btn-sm text-black ml-2"
                        style={{
                          height: "30px",
                          fontSize: "10px",
                          width: "100px",
                        }}
                      >
                        VIEW PROFILE
                      </button>
                      <button className="btn btn-primary btn-sm text-black ml-2">
                        PSYCHO
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <ReactPaginate
            previousLabel={
              <div className="pagination-label">
                <FaArrowLeft />
              </div>
            }
            nextLabel={
              <div className="pagination-label">
                <FaArrowRight />
              </div>
            }
            breakLabel="..."
            pageCount={Math.ceil(students.length / ITEMS_PER_PAGE)}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            onPageChange={handlePageClick}
            containerClassName="pagination-container pagination justify-content-end mt-3"
            pageClassName="page-item"
            pageLinkClassName="page-link"
            previousClassName="page-item"
            previousLinkClassName="page-link"
            nextClassName="page-item"
            nextLinkClassName="page-link"
            breakClassName="page-item"
            breakLinkClassName="page-link"
            activeClassName="active"
          />
        </div>

        {/*  <!-- Content Row --> */}
      </div>
    </SideBar>
  );
}

export default Students;
