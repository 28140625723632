import React, { useState } from "react";
import SideBar from "../Components/SideBar";

const Settings = () => {
  const [formData, setFormData] = useState({
    school_name: "SOUND FOUNDATION INTERNATIONAL SCHOOLS",
    school_address:
      "3<sup>rd</sup> Avenue, Phase 1, Federal Housing Authority, Lugbe - Abuja",
    school_address_second:
      "<b>Contact :</b> 0803 471 8759  <b>Email :</b> soundfoundation1@yahoo.com",
    resumption: "2024-04-22",
    stamp: "https://sofis.com.ng/upload/setting/20231206124024xfoxumlqlt.jpg",
    logo: "https://sofis.com.ng/upload/setting/20231117014650dsiivugc91.png",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleFileChange = (e) => {
    setFormData({
      ...formData,
      fevicon_icon: e.target.files[0],
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Submit logic here
    console.log(formData);
  };

  return (
    <SideBar>
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12">
            <div className="card p-4">
              <form onSubmit={handleSubmit} encType="multipart/form-data">
                <div className="box-body">
                  <div className="form-group">
                    <label className="mb-0">School Name</label>
                    <input
                      style={{ border: "1px solid lightgray", padding: "6px" }}
                      type="text"
                      className="form-control"
                      name="school_name"
                      value={formData.school_name}
                      onChange={handleInputChange}
                      required
                      placeholder="School Name"
                    />
                  </div>

                  <div className="form-group">
                    <label className="mb-0">School Address</label>
                    <input
                      style={{ border: "1px solid lightgray", padding: "6px" }}
                      type="text"
                      className="form-control"
                      name="school_address"
                      value={formData.school_address}
                      onChange={handleInputChange}
                      required
                      placeholder="School Address"
                    />
                  </div>

                  <div className="form-group">
                    <label className="mb-0">School Address Line 2</label>
                    <input
                      style={{ border: "1px solid lightgray", padding: "6px" }}
                      type="text"
                      className="form-control"
                      name="school_address_second"
                      value={formData.school_address_second}
                      onChange={handleInputChange}
                      required
                      placeholder="School Address Line 2"
                    />
                  </div>

                  <div className="form-group">
                    <label className="mb-0">Resumption Date</label>
                    <input
                      style={{ border: "1px solid lightgray", padding: "6px" }}
                      type="date"
                      className="form-control"
                      name="resumption"
                      value={formData.resumption}
                      onChange={handleInputChange}
                    />
                  </div>

                  <div className="row">
                    <div className="col-md-6">
                      <div class="form-group">
                        <label class="mb-0">
                          Logo<span style={{ color: "red" }}>*</span>
                        </label>
                        <input
                          style={{
                            border: "1px solid lightgray",
                            padding: "6px",
                          }}
                          type="file"
                          class="form-control"
                          name="logo"
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      {formData.logo && (
                        <img
                          src={formData.logo}
                          style={{ width: 100 }}
                          alt=""
                        />
                      )}
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <label className="mb-0">
                          School Stamp<span style={{ color: "red" }}>*</span>
                        </label>
                        <input
                          style={{
                            border: "1px solid lightgray",
                            padding: "6px",
                          }}
                          type="file"
                          className="form-control"
                          name="fevicon_icon"
                          onChange={handleFileChange}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      {formData.stamp && (
                        <img
                          src={formData.stamp}
                          alt="Fevicon Preview"
                          style={{ width: "100px" }}
                        />
                      )}
                    </div>
                  </div>
                </div>

                <button type="submit" className="btn btn-primary">
                  Update
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </SideBar>
  );
};

export default Settings;
