import "./Dashboard.css";
import SideBar from "../Components/SideBar";
import { useContext } from "react";
import { Store } from "../Store";
import AdminItems from "../Components/DashboardItems/AdminItems";
import TeacherItems from "../Components/DashboardItems/TeacherItems";

function Dashboard() {
  const {
    state: { userInfo },
  } = useContext(Store);

  return (
    <SideBar>
      <div className="container-fluid">
        {userInfo && userInfo.role === "admin" ? (
          <AdminItems />
        ) : userInfo && userInfo.role === "teacher" ? (
          <TeacherItems />
        ) : (
          ""
        )}
      </div>
    </SideBar>
  );
}

export default Dashboard;
