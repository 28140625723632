import React, { useState } from "react";
import SideBar from "../Components/SideBar";
import { useClassesQuery } from "../hooks/classHook";
import { useSubjectQuery } from "../hooks/subject";

const ExtranceExam = () => {
  const [entranceId, setEntranceId] = useState("4");
  const [classId, setClassId] = useState("20");
  const { data: classes } = useClassesQuery();
  const { data: subjects } = useSubjectQuery();

  // Mock students data based on selected class
  const [students] = useState([
    { id: 247, name: "OLUWAFERANMI DAVID BALOGUN" },
    { id: 248, name: "ADEBAYO TOLANI" },
  ]);

  const [marks, setMarks] = useState({
    studentId: 247,
    entranceId: 4,
    classId: 20,
    subjects: subjects || [], // Ensure subjects is initialized
  });

  const handleMarksChange = (studentId, subjectId, newMark) => {
    setMarks((prevMarks) => ({
      ...prevMarks,
      subjects: prevMarks.subjects.map((sub) =>
        sub.id === subjectId && prevMarks.studentId === studentId
          ? { ...sub, obtainedMarks: newMark }
          : sub
      ),
    }));
  };

  const handleClassChange = (e) => {
    setClassId(e.target.value);
    // You can update students based on selected class if needed
  };

  const handleReset = () => {
    setEntranceId("4");
    setClassId("20");
    setMarks((prevMarks) => ({
      ...prevMarks,
      subjects: prevMarks.subjects.map((sub) => ({
        ...sub,
        obtainedMarks: 0,
      })),
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Submitting Marks:", marks);
  };

  return (
    <SideBar>
      <section className="container-fluid">
        <div className="card p-4">
          <div className="row">
            <div className="col-md-12">
              <div className="box box-primary">
                <div className="box-header with-border">
                  <h3 className="box-title">Search Entrance Marks Register</h3>
                </div>
                <form onSubmit={handleSubmit}>
                  <div className="box-body">
                    <div className="row">
                      <div className="form-group col-md-4 pb-3">
                        <select
                          className="form-control"
                          name="entrance_id"
                          value={entranceId}
                          onChange={(e) => setEntranceId(e.target.value)}
                          required
                        >
                          <option value="">Select Entrance Exam</option>
                          <option value="4">ENTRANCE EXAM 2023/2024</option>
                        </select>
                      </div>
                      <div className="form-group col-md-4 pb-3">
                        <select
                          className="form-control"
                          name="class_id"
                          id="getClass"
                          value={classId}
                          onChange={handleClassChange}
                          required
                        >
                          <option value="">Select Class</option>
                          {classes?.map((classItem) => (
                            <option key={classItem.id} value={classItem.id}>
                              {classItem.Name}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className="form-group col-md-4 pt-1">
                        <button className="btn btn-primary" type="submit">
                          Search
                        </button>
                        <button
                          className="btn btn-success"
                          type="button"
                          onClick={handleReset}
                        >
                          Reset
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-xs-12">
              <div className="box">
                <div className="box-header">
                  <h3 className="box-title">Entrance Marks Register</h3>
                </div>
                <div className="box-body table-responsive no-padding">
                  <table className="table table-hover">
                    <thead>
                      <tr>
                        <th>Student Name</th>
                        <th>Subject</th>
                        <th>Exam</th>
                        <th>Total</th>
                        <th>Grade</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {students.map((student) =>
                        marks.subjects?.map((subject) => (
                          <tr key={`${student.id}-${subject.id}`}>
                            <td>{student.name}</td>
                            <td>
                              <b>{subject.subjectName}</b>
                            </td>
                            <td>
                              <input
                                style={{ width: "100px" }}
                                type="number"
                                value={subject.obtainedMarks || 0}
                                onChange={(e) =>
                                  handleMarksChange(
                                    student.id,
                                    subject.id,
                                    e.target.value
                                  )
                                }
                                className="form-control"
                              />
                            </td>
                            <td className="text-center">
                              {subject.obtainedMarks || 0}
                            </td>
                            <td className="text-center">{subject.grade}</td>
                            <td className="text-center">
                              <button
                                className="btn btn-success btn-sm"
                                onClick={() =>
                                  console.log(
                                    `Save clicked for ${student.name} - ${subject.subjectName}`
                                  )
                                }
                              >
                                Save
                              </button>
                            </td>
                          </tr>
                        ))
                      )}
                      <tr>
                        <td colSpan="6" className="text-center">
                          <b>Total Marks Obtainable: 200</b>
                        </td>
                        <td>
                          <b>
                            Marks Obtained:{" "}
                            {marks.subjects?.reduce(
                              (sum, sub) =>
                                sum + parseInt(sub.obtainedMarks || 0, 10),
                              0
                            )}
                          </b>
                        </td>
                        <td>
                          <b>PERCENTAGE: 61.5%</b>
                        </td>
                        <td>
                          <b>EXAM GRADE: C4</b>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="box-footer">
                  <button type="submit" className="btn btn-success">
                    SAVE
                  </button>
                  <a className="btn btn-primary" href="/print" target="_blank">
                    PRINT
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </SideBar>
  );
};

export default ExtranceExam;
