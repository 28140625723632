/* eslint-disable jsx-a11y/alt-text */
import SideBar from "../Components/SideBar";

import { useState } from "react";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import ReactPaginate from "react-paginate";

import { CgSpinner } from "react-icons/cg";

import toast from "react-hot-toast";
import AddAdminModal from "../Components/AddAdminModal";
import { useAdminQuery } from "../hooks/admin";
// import { Store } from "../Store";

const ITEMS_PER_PAGE = 10;

function Admin() {
  const [currentPage, setCurrentPage] = useState(0);
  const [showModal, setShowModal] = useState(false);

  // const {
  //   state: { userInfo },
  //   dispatch,
  // } = useContext(Store);

  const { data: admin, isPending, isError, error } = useAdminQuery();

  if (isPending) {
    return <CgSpinner size={25} />;
  }

  if (isError) {
    return toast.error(error.message);
  }

  if (!admin || admin.length === 0) {
    return <div>No admin found.</div>;
  }

  // Calculate the data to display on the current page
  const startOffset = currentPage * ITEMS_PER_PAGE;
  const endOffset = startOffset + ITEMS_PER_PAGE;
  const currentItems = admin.slice(startOffset, endOffset);

  const handlePageClick = (event) => {
    setCurrentPage(event.selected);
  };
  const boxStyle = {
    borderTop: "3px solid #3c8dbc",
  };

  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);

  return (
    <SideBar>
      <div className="container-fluid">
        <div class="card w-100 py-2 px-2 mb-3" style={boxStyle}>
          <div class="box-header with-border">
            <h3 class="box-title text-black">Search Admin</h3>
          </div>
          <form action="" method="get">
            <div class="box-body">
              <div class="row">
                <div class="form-group no-margin col-md-4">
                  <label for="exampleInputEmail1">Name</label>
                  <input
                    type="text"
                    value=""
                    name="name"
                    class="form-control"
                    placeholder="Search name"
                  />
                </div>
                <div class="form-group no-margin col-md-4">
                  <label for="exampleInputEmail1">Email</label>
                  <input
                    type="text"
                    value=""
                    name="name"
                    class="form-control"
                    placeholder="Search email"
                  />
                </div>
                <div
                  class="form-group no-margin col-md-4"
                  style={{ paddingTop: "33px" }}
                >
                  <button type="submit" class="btn btn-primary">
                    Search
                  </button>
                  <button type="submit" class="btn ml-2 btn-success">
                    Reset
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>

        {/*  <!-- Content Row --> */}
        <div className="row" style={{ overflowX: "auto" }}>
          <div className="col-md-12">
            <div className="px-4 d-flex justify-content-between align-items-center">
              <p className="mb-0">Admin List</p>
              <button
                className="btn btn-success btn-sm"
                onClick={handleShowModal}
              >
                Add Admin
              </button>
            </div>
          </div>

          {/* AddAdminModal Component */}
          <AddAdminModal show={showModal} handleClose={handleCloseModal} />

          {isPending ? <CgSpinner size={25} /> : ""}
          <table className="table table-hover">
            <thead className="thead-light">
              <tr>
                <th className="text-center">USERID</th>
                <th className="text-center">PHOTO</th>
                <th>USER</th>
                <th className="text-center">LAST SEEN</th>
                <th className="text-center">Action</th>
              </tr>
            </thead>
            <tbody>
              {currentItems.map((item) => (
                <tr key={item.SN}>
                  <td className="text-center">{item.id}</td>

                  <td className="text-center">
                    <img
                      className="rounded-circle"
                      style={{ width: "50px", height: "50px" }}
                      src="img/undraw_profile.svg"
                    />
                  </td>
                  <td>
                    <p>{item.full_name}</p>
                    <span
                      className={`badge ${
                        item.status === "ACTIVE"
                          ? "bg-success text-white"
                          : "bg-secondary text-white"
                      }`}
                    >
                      {item.status}
                    </span>
                  </td>
                  <td className="text-center">
                    {/* {userInfo.user ? (
                      <span className="text-success">online</span>
                    ) : (
                      ""
                    )} */}
                  </td>
                  <td className="d-flex justify-content-end">
                    <button className="btn btn-success text-black">edit</button>
                    <button className="btn btn-info text-black ml-2">
                      chart
                    </button>
                    <button className="btn text-black btn-warning ml-2">
                      view profile
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <ReactPaginate
          previousLabel={
            <div className="pagination-label">
              <FaArrowLeft />
            </div>
          }
          nextLabel={
            <div className="pagination-label">
              <FaArrowRight />
            </div>
          }
          breakLabel="..."
          pageCount={Math.ceil(admin.length / ITEMS_PER_PAGE)}
          marginPagesDisplayed={2}
          pageRangeDisplayed={5}
          onPageChange={handlePageClick}
          containerClassName="pagination-container pagination justify-content-end mt-3"
          pageClassName="page-item"
          pageLinkClassName="page-link"
          previousClassName="page-item"
          previousLinkClassName="page-link"
          nextClassName="page-item"
          nextLinkClassName="page-link"
          breakClassName="page-item"
          breakLinkClassName="page-link"
          activeClassName="active"
        />

        {/*  <!-- Content Row --> */}
      </div>
    </SideBar>
  );
}

export default Admin;
