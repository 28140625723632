/** @format */

import { useMutation } from "@tanstack/react-query";
import apiClient from "../apiClient";

export const useSigninMutation = () => {
  return useMutation({
    mutationFn: async ({ email, password }) =>
      (
        await apiClient.post(`api/users/login`, {
          email,
          password,
        })
      ).data,
  });
};
