import React, { useEffect, useState } from "react";
import SideBar from "../Components/SideBar";
import { convertToBase64 } from "../utils";

import toast from "react-hot-toast";
import {
  useHomepostQueryData,
  useUpdateHomepagePostDataMutation,
} from "../hooks/admin";

const Postpage = () => {
  const [formData, setFormData] = useState({
    post_image: "",
    post_title: "",
    post_paragraph: "",
  });

  const { data, isLoading, isError, error, refetch } = useHomepostQueryData();

  const mutation = useUpdateHomepagePostDataMutation();

  useEffect(() => {
    if (data) {
      setFormData(data);
    }
  }, [data]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleFileChange = async (e) => {
    const { name } = e.target;
    const file = e.target.files[0];

    if (file) {
      const base64 = await convertToBase64(file);
      setFormData((prevData) => ({
        ...prevData,
        [name]: base64,
      }));
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    mutation.mutate(formData, {
      onSuccess: () => {
        toast.success("Update successful");
      },
      onError: (error) => {
        toast.error("Update failed: " + error.message);
      },
    });
    refetch();
  };

  if (isLoading) return <div>Loading...</div>;
  if (isError) return <div>Error: {error.message}</div>;

  return (
    <SideBar>
      <div className="container-fluid">
        <div className="box-header with-border">
          <h3 className="box-title">Post</h3>
        </div>
        <form onSubmit={handleSubmit} encType="multipart/form-data">
          <div className="box-body">
            <div className="row">
              {/* title */}
              <div className="col-md-12 ">
                <div className="row">
                  <div className="form-group col-md-12">
                    <label>
                      Post Title<span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      type="text"
                      name="post_title"
                      value={formData.post_title}
                      onChange={handleChange}
                      className="form-control"
                    />
                  </div>
                </div>
              </div>
              {/* Post */}
              <div className="col-md-12">
                <div className="row">
                  <div className="form-group col-md-6">
                    <label>
                      Post Image<span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      type="file"
                      name="post_image"
                      onChange={handleFileChange}
                      className="form-control"
                    />
                  </div>
                  <div className="col-md-6">
                    {formData.post_image && (
                      <div className="image-preview">
                        <img
                          src={formData.post_image}
                          alt={formData.post_image}
                          width={200}
                          height={150}
                          style={{
                            border: "1px solid #ddd",
                            borderRadius: "4px",
                          }}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="col-md-12">
                <div className="row">
                  <div className="form-group col-md-12">
                    <label>
                      Post Write up
                      <span style={{ color: "red" }}>*</span>
                    </label>
                    <textarea
                      rows={10}
                      name="post_paragraph"
                      value={formData.post_paragraph}
                      onChange={handleChange}
                      className="form-control"
                      required
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <button type="submit" className="btn btn-primary mt-2">
            Update
          </button>
        </form>
      </div>
    </SideBar>
  );
};

export default Postpage;
