import React, { useState, useEffect } from "react";
import SideBar from "../Components/SideBar";
import { convertToBase64 } from "../utils";

import toast from "react-hot-toast";
import {
  useHomepageQueryData,
  useUpdateHomepageDataMutation,
} from "../hooks/admin";

const CustomizeHomepage = () => {
  const [formData, setFormData] = useState({
    service_title_one: "",
    service_title_two: "",
    service_title_three: "",
    service_title_four: "",
    service_description_one: "",
    service_description_two: "",
    service_description_three: "",
    service_description_four: "",
    about_image: "",
    about_title: "",
    about_paragraph: "",
    highlight_one: "",
    highlight_two: "",
    highlight_three: "",
    highlight_four: "",
    highlight_five: "",
    highlight_six: "",
    highlight_seven: "",
    highlight_eight: "",
    gallary_title: "",
    picture_one: "",
    picture_two: "",
    picture_three: "",
    picture_four: "",
    testimonial_one: "",
    testimonial_pro_one: "",
    testimonial_text_one: "",
    testimonial_two: "",
    testimonial_pro_two: "",
    testimonial_text_two: "",
    testimonial_three: "",
    testimonial_pro_three: "",
    testimonial_text_three: "",
    testimonial_picture_one: "",
    testimonial_picture_two: "",
    testimonial_picture_three: "",
  });

  const { data, isLoading, isError, error, refetch } = useHomepageQueryData();

  const mutation = useUpdateHomepageDataMutation();

  useEffect(() => {
    if (data) {
      setFormData(data);
    }
  }, [data]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleFileChange = async (e) => {
    const { name } = e.target;
    const file = e.target.files[0];

    if (file) {
      const base64 = await convertToBase64(file);
      setFormData((prevData) => ({
        ...prevData,
        [name]: base64,
      }));
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    mutation.mutate(formData, {
      onSuccess: () => {
        toast.success("Update successful");
      },
      onError: (error) => {
        toast.error("Update failed: " + error.message);
      },
    });
    refetch();
  };

  if (isLoading) return <div>Loading...</div>;
  if (isError) return <div>Error: {error.message}</div>;

  return (
    <SideBar>
      <div className="container-fluid">
        <div className="box-header with-border">
          <h3 className="box-title">Customize Homepage</h3>
        </div>
        <form onSubmit={handleSubmit} encType="multipart/form-data">
          <div className="box-body">
            <div className="row">
              {/* services */}
              <div className="col-md-12">
                <div className="row">
                  <div className="form-group col-md-6">
                    <label>
                      Service Title 1<span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      type="text"
                      name="service_title_one"
                      value={formData.service_title_one}
                      onChange={handleChange}
                      className="form-control"
                      required
                    />
                  </div>

                  <div className="form-group col-md-6">
                    <label>
                      Service Title 2<span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      type="text"
                      name="service_title_two"
                      value={formData.service_title_two}
                      onChange={handleChange}
                      className="form-control"
                      required
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-12">
                <div className="row">
                  <div className="form-group col-md-6">
                    <label>
                      Service Title 3<span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      type="text"
                      name="service_title_three"
                      value={formData.service_title_three}
                      onChange={handleChange}
                      className="form-control"
                      required
                    />
                  </div>

                  <div className="form-group col-md-6">
                    <label>
                      Service Title 4<span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      type="text"
                      name="service_title_four"
                      value={formData.service_title_four}
                      onChange={handleChange}
                      className="form-control"
                      required
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-12">
                <div className="row">
                  <div className="form-group col-md-6">
                    <label>
                      Service Description 1
                      <span style={{ color: "red" }}>*</span>
                    </label>
                    <textarea
                      name="service_description_one"
                      value={formData.service_description_one}
                      onChange={handleChange}
                      className="form-control"
                      required
                    />
                  </div>
                  <div className="form-group col-md-6">
                    <label>
                      Service Description 2
                      <span style={{ color: "red" }}>*</span>
                    </label>
                    <textarea
                      name="service_description_two"
                      value={formData.service_description_two}
                      onChange={handleChange}
                      className="form-control"
                      required
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-12">
                <div className="row">
                  <div className="form-group col-md-6">
                    <label>
                      Service Description 3
                      <span style={{ color: "red" }}>*</span>
                    </label>
                    <textarea
                      name="service_description_three"
                      value={formData.service_description_three}
                      onChange={handleChange}
                      className="form-control"
                      required
                    />
                  </div>
                  <div className="form-group col-md-6">
                    <label>
                      Service Description 4
                      <span style={{ color: "red" }}>*</span>
                    </label>
                    <textarea
                      name="service_description_four"
                      value={formData.service_description_four}
                      onChange={handleChange}
                      className="form-control"
                      required
                    />
                  </div>
                </div>
              </div>
              {/* about us */}
              <div className="col-md-12">
                <div className="row">
                  <div className="form-group col-md-6">
                    <label>
                      About us Image<span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      type="file"
                      name="about_image"
                      onChange={handleFileChange}
                      className="form-control"
                    />
                    {formData.about_image && (
                      <div className="image-preview">
                        <img
                          src={formData.about_image}
                          alt={formData.about_title}
                          width={100}
                          height={100}
                          style={{
                            border: "1px solid #ddd",
                            borderRadius: "4px",
                          }}
                        />
                      </div>
                    )}
                  </div>
                  <div className="form-group col-md-6">
                    <label>
                      About us Title<span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      type="text"
                      value={formData.about_title}
                      name="about_title"
                      onChange={handleFileChange}
                      className="form-control"
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-12">
                <div className="row">
                  <div className="form-group col-md-12">
                    <label>
                      About us Write up
                      <span style={{ color: "red" }}>*</span>
                    </label>
                    <textarea
                      rows={10}
                      name="about_paragraph"
                      value={formData.about_paragraph}
                      onChange={handleChange}
                      className="form-control"
                      required
                    />
                  </div>
                </div>
              </div>
              {/* high light */}
              <div className="col-md-12">
                <div className="row">
                  <div className="form-group col-md-3">
                    <label>
                      Highlight 1<span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      type="text"
                      name="highlight_one"
                      value={formData.highlight_one}
                      onChange={handleChange}
                      className="form-control"
                      required
                    />
                  </div>
                  <div className="form-group col-md-3">
                    <label>
                      Highlight 2<span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      type="text"
                      name="highlight_two"
                      value={formData.highlight_two}
                      onChange={handleChange}
                      className="form-control"
                      required
                    />
                  </div>
                  <div className="form-group col-md-3">
                    <label>
                      Highlight 3<span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      type="text"
                      name="highlight_three"
                      value={formData.highlight_three}
                      onChange={handleChange}
                      className="form-control"
                      required
                    />
                  </div>
                  <div className="form-group col-md-3">
                    <label>
                      Highlight 4<span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      type="text"
                      name="highlight_four"
                      value={formData.highlight_four}
                      onChange={handleChange}
                      className="form-control"
                      required
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-12">
                <div className="row">
                  <div className="form-group col-md-3">
                    <label>
                      Highlight 5<span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      type="text"
                      name="highlight_five"
                      value={formData.highlight_five}
                      onChange={handleChange}
                      className="form-control"
                      required
                    />
                  </div>
                  <div className="form-group col-md-3">
                    <label>
                      Highlight 6<span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      type="text"
                      name="highlight_six"
                      value={formData.highlight_six}
                      onChange={handleChange}
                      className="form-control"
                      required
                    />
                  </div>
                  <div className="form-group col-md-3">
                    <label>
                      Highlight 7<span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      type="text"
                      name="highlight_seven"
                      value={formData.highlight_seven}
                      onChange={handleChange}
                      className="form-control"
                      required
                    />
                  </div>
                  <div className="form-group col-md-3">
                    <label>
                      Highlight 8<span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      type="text"
                      name="highlight_eight"
                      value={formData.highlight_eight}
                      onChange={handleChange}
                      className="form-control"
                      required
                    />
                  </div>
                </div>
              </div>

              {/* Gallery */}
              <div className="col-md-12">
                <div className="row">
                  <label>
                    Gallery Title<span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    type="text"
                    name="gallary_title"
                    value={formData.gallary_title}
                    onChange={handleChange}
                    className="form-control"
                    required
                  />
                </div>
              </div>
              <div className="col-md-12">
                <div className="row">
                  <div className="form-group col-md-6">
                    <label>
                      Gallery Picture 1<span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      type="file"
                      name="picture_one"
                      onChange={handleFileChange}
                      className="form-control"
                    />
                    {formData.picture_one && (
                      <div className="image-preview">
                        <img
                          src={formData.picture_one}
                          alt={formData.about_title}
                          width={100}
                          height={100}
                          style={{
                            border: "1px solid #ddd",
                            borderRadius: "4px",
                          }}
                        />
                      </div>
                    )}
                  </div>
                  <div className="form-group col-md-6">
                    <label>
                      Gallery Picture 2<span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      type="file"
                      name="picture_two"
                      onChange={handleFileChange}
                      className="form-control"
                    />
                    {formData.picture_two && (
                      <div className="image-preview">
                        <img
                          src={formData.picture_two}
                          alt={formData.about_title}
                          width={100}
                          height={100}
                          style={{
                            border: "1px solid #ddd",
                            borderRadius: "4px",
                          }}
                        />
                      </div>
                    )}
                  </div>
                  <div className="form-group col-md-6">
                    <label>
                      Gallery Picture 3<span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      type="file"
                      name="picture_three"
                      onChange={handleFileChange}
                      className="form-control"
                    />
                    {formData.picture_three && (
                      <div className="image-preview">
                        <img
                          src={formData.picture_three}
                          alt={formData.about_title}
                          width={100}
                          height={100}
                          style={{
                            border: "1px solid #ddd",
                            borderRadius: "4px",
                          }}
                        />
                      </div>
                    )}
                  </div>
                  <div className="form-group col-md-6">
                    <label>
                      Gallery Picture 4<span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      type="file"
                      name="picture_four"
                      onChange={handleFileChange}
                      className="form-control"
                    />
                    {formData.picture_four && (
                      <div className="image-preview">
                        <img
                          src={formData.picture_four}
                          alt={formData.about_title}
                          width={100}
                          height={100}
                          style={{
                            border: "1px solid #ddd",
                            borderRadius: "4px",
                          }}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
              {/* Testimonials */}
              <div className="col-md-12">
                {/* Testimonial Section */}
                <div className="row">
                  <div className="form-group col-md-4">
                    <label>
                      Testimonial 1<span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      type="text"
                      name="testimonial_one"
                      value={formData.testimonial_one}
                      onChange={handleChange}
                      className="form-control"
                      required
                    />
                  </div>
                  <div className="form-group col-md-4">
                    <label>Testimonial Profession 1</label>
                    <input
                      type="text"
                      name="testimonial_pro_one"
                      value={formData.testimonial_pro_one}
                      onChange={handleChange}
                      className="form-control"
                    />
                  </div>
                  <div className="form-group col-md-4">
                    <label htmlFor="">Testimonial Image 1</label>
                    <input
                      type="file"
                      name="testimonial_picture_one"
                      onChange={handleFileChange}
                      className="form-control"
                    />
                    {formData.testimonial_picture_one && (
                      <div className="image-preview">
                        <img
                          src={formData.testimonial_picture_one}
                          alt={formData.about_title}
                          width={100}
                          height={100}
                          style={{
                            border: "1px solid #ddd",
                            borderRadius: "4px",
                          }}
                        />
                      </div>
                    )}
                  </div>
                </div>
                <div className="row">
                  <div className="form-group col-md-4">
                    <label>
                      Testimonial 2<span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      type="text"
                      name="testimonial_two"
                      value={formData.testimonial_two}
                      onChange={handleChange}
                      className="form-control"
                      required
                    />
                  </div>
                  <div className="form-group col-md-4">
                    <label>Testimonial Profession 2</label>
                    <input
                      type="text"
                      name="testimonial_pro_two"
                      value={formData.testimonial_pro_two}
                      onChange={handleChange}
                      className="form-control"
                    />
                  </div>
                  <div className="form-group col-md-4">
                    <label htmlFor="test">testimonial Image 2</label>
                    <input
                      type="file"
                      onChange={handleFileChange}
                      name="testimonial_picture_two"
                      className="form-control"
                    />
                    {formData.testimonial_picture_two && (
                      <div className="image-preview">
                        <img
                          src={formData.testimonial_picture_two}
                          alt={formData.about_title}
                          width={100}
                          height={100}
                          style={{
                            border: "1px solid #ddd",
                            borderRadius: "4px",
                          }}
                        />
                      </div>
                    )}
                  </div>
                </div>
                <div className="row">
                  <div className="form-group col-md-4">
                    <label>
                      Testimonial 3<span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      type="text"
                      name="testimonial_three"
                      value={formData.testimonial_three}
                      onChange={handleChange}
                      className="form-control"
                      required
                    />
                  </div>
                  <div className="form-group col-md-4">
                    <label>Testimonial Profession 3</label>
                    <input
                      type="text"
                      name="testimonial_pro_three"
                      value={formData.testimonial_pro_three}
                      onChange={handleChange}
                      className="form-control"
                    />
                  </div>
                  <div className="form-group col-md-4">
                    <label htmlFor="">Testimonial Image 3</label>
                    <input
                      type="file"
                      name="testimonial_picture_three"
                      onChange={handleFileChange}
                      className="form-control"
                    />
                    {formData.testimonial_picture_three && (
                      <div className="image-preview">
                        <img
                          src={formData.testimonial_picture_three}
                          alt={formData.about_title}
                          width={100}
                          height={100}
                          style={{
                            border: "1px solid #ddd",
                            borderRadius: "4px",
                          }}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="col-md-12">
                <div className="row">
                  <div className="col-md-4">
                    <label>Testimonial Text 1</label>
                    <textarea
                      name="testimonial_text_one"
                      value={formData.testimonial_text_one}
                      onChange={handleChange}
                      className="form-control"
                    />
                  </div>
                  <div className="col-md-4">
                    <label>Testimonial Text 3</label>
                    <textarea
                      name="testimonial_text_two"
                      value={formData.testimonial_text_two}
                      onChange={handleChange}
                      className="form-control"
                    />
                  </div>
                  <div className="col-md-4">
                    <label>Testimonial Text 3</label>
                    <textarea
                      name="testimonial_text_three"
                      value={formData.testimonial_text_three}
                      onChange={handleChange}
                      className="form-control"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <button type="submit" className="btn btn-primary mt-2">
            Update
          </button>
        </form>
      </div>
    </SideBar>
  );
};

export default CustomizeHomepage;
