/** @format */

import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import apiClient from "../apiClient";

// Hook for fetching all teachers
export const useTeachersQuery = () => {
  return useQuery({
    queryKey: ["teachers"],
    queryFn: async () => {
      const { data } = await apiClient.get("api/teachers/all-teachers");
      return data;
    },
  });
};

// Hook for adding a new class
export const useAddTeacherMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (teacher) =>
      (await apiClient.post("api/teachers/add-teacher", teacher)).data,
    onSuccess: () => {
      queryClient.invalidateQueries(["teachers"]);
    },
  });
};

// Hook for editing a class
export const useEditClassMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({ id, updated }) =>
      (await apiClient.put(`api/teachers/${id}`, updated)).data,
    onSuccess: () => {
      queryClient.invalidateQueries(["teachers"]);
    },
  });
};

// Hook for deleting a class
export const useDeleteClassMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (id) =>
      (await apiClient.delete(`api/teachers/${id}`)).data,
    onSuccess: () => {
      queryClient.invalidateQueries(["teachers"]);
    },
  });
};
