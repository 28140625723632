/** @format */

import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import apiClient from "../apiClient";

// Hook for fetching all admins
export const useAdminQuery = () => {
  return useQuery({
    queryKey: ["admins"],
    queryFn: async () => {
      const { data } = await apiClient.get("api/admins/all-admin");
      return data;
    },
  });
};

// Hook for fetching homepage data
export const useHomepageQueryData = () => {
  return useQuery({
    queryKey: ["homepage-data"], // Unique key for the query
    queryFn: async () => {
      const { data } = await apiClient.get("api/admins/get-homepage-data");
      return data;
    },
  });
};

// Hook for fetching homepage post data
export const useHomepostQueryData = () => {
  return useQuery({
    queryKey: ["homepage-data"], // Unique key for the query
    queryFn: async () => {
      const { data } = await apiClient.get("api/admins/get-homepost-data");
      return data;
    },
  });
};

// Hook for adding a new class
export const useAddAdminMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (admin) =>
      (await apiClient.post("api/admins/add-admin", admin)).data,
    onSuccess: () => {
      queryClient.invalidateQueries(["admins"]);
    },
  });
};

// Hook for adding or updating a student
export const useAddOrUpdateStudentMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (student, userid) => {
      return (
        await apiClient.post(
          "api/admins/add-or-update-student",
          student,
          userid
        )
      ).data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries(["students"]);
    },
  });
};

// Hook for saving attendance
export const useSaveAttendanceMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (attendanceData) => {
      return (
        await apiClient.post(
          "api/admins/add-or-update-attendance",
          attendanceData
        )
      ).data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries(["attendance"]);
    },
  });
};

// Hook for updating homepage data
export const useUpdateHomepageDataMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (homepageData) =>
      (await apiClient.post("api/admins/update-homepage-data", homepageData))
        .data,
    onSuccess: () => {
      queryClient.invalidateQueries(["homepagedata"]);
    },
  });
};
// Hook for updating homepage data
export const useUpdateHomepagePostDataMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (homepagePostData) =>
      (await apiClient.post("api/admins/update-post", homepagePostData)).data,
    onSuccess: () => {
      queryClient.invalidateQueries(["homepagedata"]);
    },
  });
};
// Hook for adding a new class
export const useTermMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (term) =>
      (await apiClient.post("api/admins/add-term", term)).data,
    onSuccess: () => {
      queryClient.invalidateQueries(["terms"]);
    },
  });
};
// Hook for fetching all terms
export const useGetTermsQuery = () => {
  return useQuery({
    queryKey: ["terms"],
    queryFn: async () => {
      const { data } = await apiClient.get("api/admins/terms");
      return data;
    },
  });
};

// Hook for fetching all terms
export const useGetAttendanceQuery = () => {
  return useQuery({
    queryKey: ["attendance"],
    queryFn: async () => {
      const { data } = await apiClient.get("api/admins/attendance");
      return data;
    },
  });
};
