import React, { useState } from "react";
import { Link } from "react-router-dom";
import SideBar from "../Components/SideBar";
import AddTermModal from "../Components/AddTermModal";
import { useGetTermsQuery } from "../hooks/admin";

const Exam = () => {
  const [showModal, setShowModal] = useState(false);
  const [editingTerm, setEditingTerm] = useState(null);
  const { data: terms } = useGetTermsQuery();

  // Function to derive the note based on the term name
  const getNoteFromTermName = (termName) => {
    if (termName === "1") return "1ST";
    if (termName === "2") return "2ND";
    if (termName === "3") return "3RD";
    return ""; // Default case
  };

  const handleSearch = (event) => {
    event.preventDefault();
  };

  const handleShowModal = (term = null) => {
    setEditingTerm(term); // Set the editing term if provided
    setShowModal(true); // Show the modal
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setEditingTerm(null); // Clear editing term when closing modal
  };

  // Handle editing term
  const handleEditTerm = (term) => {
    handleShowModal(term); // Show the modal for editing
  };

  return (
    <SideBar>
      <div className="container-fluid">
        <div className="row w-100">
          <div className="card px-2 w-100">
            <div className="box box-primary">
              <div className="box-header with-border">
                <h3 className="box-title">Search Exam</h3>
              </div>
              <form onSubmit={handleSearch}>
                <div className="box-body">
                  <div className="row">
                    <div className="form-group no-margin col-md-6">
                      <label htmlFor="name">Name</label>
                      <input
                        type="text"
                        id="name"
                        name="name"
                        className="form-control"
                        placeholder="Search Exam"
                      />
                    </div>
                    <div
                      className="form-group no-margin col-md-6"
                      style={{ paddingTop: "30px", paddingBottom: "20px" }}
                    >
                      <button type="submit" className="btn btn-primary">
                        Search
                      </button>
                      <a
                        href="https://sofis.com.ng/panel/admin/examinations/exam/list"
                        className="btn btn-success ml-2"
                      >
                        Reset
                      </a>
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <div className="box">
              <div className="box-header">
                <h3 className="box-title">Exam List ({terms?.length})</h3>
                <div className="box-tools">
                  <div className="input-group mb-2 d-flex justify-content-end input-group-sm">
                    <button
                      className="btn btn-success btn-sm"
                      style={{ color: "white" }}
                      onClick={() => handleShowModal(null)} // Pass null for adding new exam
                    >
                      Add New Exam
                    </button>
                  </div>
                </div>
              </div>
              <AddTermModal
                show={showModal}
                handleClose={handleCloseModal}
                editingTerm={editingTerm}
              />
              <div className="box-body table-responsive no-padding">
                <table className="table table-hover">
                  <thead>
                    <tr>
                      <th>SN</th>
                      <th>Name</th>
                      <th>Note</th>
                      <th>Status</th>
                      <th>Created Date</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {terms?.map((term, index) => (
                      <tr key={term.id}>
                        <td>{index + 1}</td>
                        <td>
                          {term.term_name === "1"
                            ? "FIRST TERM"
                            : term.term_name === "2"
                            ? "SECOND TERM"
                            : term.term_name === "3"
                            ? "THIRD TERM"
                            : "UNKNOWN TERM"}{" "}
                          {term.date}
                        </td>
                        <td>{getNoteFromTermName(term.term_name)}</td>
                        <td>
                          <span
                            className={`badge ${
                              term.status === "ACTIVE"
                                ? "bg-success text-white"
                                : "bg-secondary text-white"
                            }`}
                          >
                            {term.status}
                          </span>
                        </td>
                        <td>{term.created_at}</td>
                        <td className="d-flex">
                          <button
                            className="text-white btn btn-success btn-sm"
                            onClick={() => handleEditTerm(term)} // Open edit for this term
                          >
                            Edit
                          </button>
                          <Link
                            className="text-white btn btn-danger btn-sm ml-2"
                            to={`/panel/admin/examinations/exam/delete/${term.id}`}
                            onClick={(e) => {
                              if (
                                !window.confirm(
                                  "Are you sure you want to delete?"
                                )
                              ) {
                                e.preventDefault();
                              }
                            }}
                          >
                            Delete
                          </Link>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <div style={{ float: "right" }}></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </SideBar>
  );
};

export default Exam;
